import React, {createRef, useEffect, useState} from 'react';
import {Table as AntTable} from "antd";
import api from "../middleware/api";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const Table = ({ endpoint, columns, onClick, sortField, sortOrder, toggleReload, filter, rowClassName, enableDragDrop, pagination, pageSize}) => {

    const [tableData, setTableData] = useState({ data: [], count: 0 });
    const [tableLoading, setTableLoading] = useState(false);
    const [tableState, setTableState] = useState({
        page: 1,
        pageSize: pageSize || 50,
        sortField: sortField,
        sortOrder: sortOrder,
        filter,
    });
    const lastDefaultOrder = createRef();

    useEffect(() => {
        let newState = {...tableState, filter};

        if(lastDefaultOrder.current !== sortOrder) {
            newState.sortOrder = sortOrder;
        }

        setTableState(newState)
    }, [sortOrder, filter]);


    const fetchTableData = async () => {
        setTableLoading(true);

        const { filter: _filter, ...tableStateNoFilter } = tableState;
        const _tableState = {...tableStateNoFilter, ..._filter, sortField: tableStateNoFilter.sortField ? (columns.find(c => c.dataIndex === tableStateNoFilter.sortField)?.sortField || tableStateNoFilter.sortField ) : undefined }

        if(!pagination && pagination !== undefined) {
            _tableState.pageSize = 9999;
        }

        const response = await api.get(`${endpoint}/table`, { params: {...(_tableState || {}) } });
        setTableLoading(false);
        if(response?.data?.data && Array.isArray(response.data.data))
        {
            setTableData(response.data);
        }
    };

    useEffect(() => {
        fetchTableData();
    }, [tableState, toggleReload]);


    const handleTableChange = (pagination, filters, sorter) => {
        setTableState({
            ...tableState,
            page: pagination.current,
            pageSize: pagination.pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order,
        });
    };


    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(tableData.data), oldIndex, newIndex).filter(
                el => !!el,
            );
            //console.log('Sorted items: ', newData.map(data => data.id));
            setTableData( {data: newData, count: tableData.count } );
            api.patch(`${endpoint}/table/sequence`, {sortedIds: newData.map(data => data.id)});
        }
    };

    const DraggableContainer = props => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = tableData.data.findIndex(x => x.orderIndex === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };


    return (
        <AntTable
            dataSource={tableData.data.map(data => ({key: data.id, ...data}))}
            pagination={pagination === undefined ? {total: tableData.count, pageSize: tableState.pageSize, current: tableState.page} : pagination}
            columns={tableData.data ? columns.map(c => { c.sortOrder = (tableState.sortField === c.dataIndex && tableState.sortOrder); return c; }) : columns}
            onChange={handleTableChange}
            loading={tableLoading}
            onRow={(record) => ({
                onClick: () => {
                    onClick && onClick(record);
                }
            })}
            scroll={{x: '100%'}}
            rowKey={enableDragDrop ? "orderIndex" : undefined}
            rowClassName={rowClassName ? rowClassName :(record, index) => `${onClick ? 'table-clickable' : ''}`}
            components={enableDragDrop ? {
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                },
            } : undefined}
        />
    );
}

export default Table;
