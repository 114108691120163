import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import api from "../../middleware/api";
import {useAuth} from "../auth/AuthProvider";


const MandatorChooser = ({width}) => {

    const {userInfo, changeMandator} = useAuth();
    const [mandators, setMandators] = useState([]);

    const loadMandators = async () => {
        const response = await api.get(`mandator`);
        if(response.data) {
            setMandators(response.data);
        }
    };

    useEffect(() => {
        loadMandators();
    }, []);

    if((userInfo?.mandatorIds || []).length <= 1) {
        return <></>;
    }

    return <div style={{padding: '15px 15px 25px 15px'}}>
        <Select
            style={{width: '100%'}}
            value={userInfo.mandator}
            onChange={changeMandator}
        >
            {mandators.map(mandator => <Select.Option key={mandator.id} value={mandator.id}>{mandator.name}</Select.Option>)}
        </Select>
    </div>;
};

export default MandatorChooser;
