import React, {useEffect, useState} from 'react';
import api, {API_URL} from "../../../middleware/api";
import {
    Row,
    Col,
    Input,
    Modal,
    Spin,
    Button,
    DatePicker,
    Divider,
    Upload,
    Select,
    Checkbox,
    AutoComplete,
    Tabs
} from "antd";
import {Form, message} from "antd";
import {ExclamationCircleOutlined,} from '@ant-design/icons'
import moment from "moment";
import {useAuth} from "../../auth/AuthProvider";

const { TabPane } = Tabs;

const MandatorDetail = ({id, close}) => {

    const [data, setData] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [categories, setCategories] = useState([]);
    const { userInfo } = useAuth();


    const { JWT } = useAuth();
    const [form] = Form.useForm();

    const fetchData = async () => {
        try {
            setDataLoading(true);
            const response = await api.get(`mandator/${id}`);
            if(response?.data)
            {
                const data = {...response.data,
                    createdAt: response.data.createdAt ?  moment(response.data.createdAt) : undefined,
                    updatedAt: response.data.updatedAt ? moment(response.data.updatedAt) : undefined,
                    breaks: response.data.breaks && Array.isArray(response.data.breaks) ? response.data.breaks.map(actBreak => moment(actBreak, 'HH:mm')) : undefined,
                };
                setData(data);
            }
        } catch(e) {
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    const loadCategories = async () => {
        const response = await api.get(`product/categories`);
        if(response.data) {
            setCategories(response.data.map(category => {return {
                label: category,
                value: category
            }}))
        }
    };

    const deleteData = async () => {
        Modal.confirm({
            title:
                'Möchten Sie diesen Datensatz wirklich löschen?',
            icon: <ExclamationCircleOutlined />,
            content: <span />,
            okText: 'Löschen',
            okType: 'danger',
            cancelText: 'Behalten',
            onOk: async () => {
                try {
                    setDataLoading(true);
                    await api.delete(`mandator/${id}`);
                    close();
                } catch(e) {
                    message.error("Löschen fehlgeschlagen");
                } finally {
                    setDataLoading(false);
                }
            },
            onCancel() {},
        });
    };



    useEffect(() => {
        setData({});
        if (id && id !== 'new') {
            fetchData();
        }

        id && loadCategories()
    }, [id]);

    useEffect(() => {
        form.resetFields();
    }, [data]);



    const handleOk = () => {
        form.submit();
    };

    const handleSubmit = async (values) => {
        try {
            setSaving(true);
            values = {
                ...values,
                breaks: values.breaks && Array.isArray(values.breaks) ? values.breaks.map(actBreak => actBreak.local().format("HH:mm")) : undefined,
                id: undefined,
            };

            if (id && id !== 'new') {
                await api.patch(`mandator/${id}`, values);
            } else {
                await api.put(`mandator`, values);
            }

            message.success("Gespeichert");
            close();
        } catch(e) {
            message.error("Speichern fehlgeschlagen");
        } finally {
            setSaving(false);
        }
    };


    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList.slice(-1);
    };

    return (
        <Modal
            maskClosable={false}
            destroyOnClose={true}
            visible={!!id}
            title={id !== 'new' ? "Mandant bearbeiten" : "Mandant anlegen"}
            onCancel={close}
            onOk={handleOk}
            width={"768px"}
            footer={[
                <>
                    {id !== 'new' && userInfo?.roles?.includes('admin') ?
                        <Button key="delete" type="danger" onClick={deleteData}>
                            Löschen
                        </Button>
                    : null}
                </>,
                <Button key="cancel" type="default" onClick={close}>
                    Abbrechen
                </Button>,
                <Button key="ok" type="primary" onClick={handleOk}>
                    Speichern
                </Button>,

            ]}
        >
            <Spin spinning={dataLoading || saving}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={data}
                    layout={"vertical"}
                >
                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="Allgemein" key="1">
                            <Row
                                gutter={16}
                            >
                                <Col span={24}>
                                    <Divider orientation="left">Allgemein</Divider>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={"Name"}
                                        name={"name"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Firma"}
                                        name={"company"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Straße, Hausnummer"}
                                        name={"address"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"PLZ"}
                                        name={"zipCode"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"Ort"}
                                        name={"city"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Steuer ID"}
                                        name={"taxId"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Unternehmensform"}
                                        name={"corporateForm"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Handelsregister"}
                                        name={"commercialRegister"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Geschäftsführer"}
                                        name={"managingDirector"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Telefon"}
                                        name={"phone"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Internetseite"}
                                        name={"web"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Mail Adresse"}
                                        name={"mail"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Online Buchungs URL"}
                                        name={"onlineURL"}
                                        help={"Adresse des Online Buchungssystems (ohne slash am ende) z.B: https://buchung.xyz.de"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Datenschutz URL"}
                                        name={"privacyUrl"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Impressum URL"}
                                        name={"imprintUrl"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Bewertung URL"}
                                        name={"ratingLink"}
                                        help={"Wenn dieser Link gesetzt wird, erhält der Kunde nach seinem Besuch eine E-Mail um seinen Aufenthalt bewerten zu können."}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Buchungs benachr. E-Mail Adresse"}
                                        name={"bookingNotificationMail"}
                                        rules={[{type: "email"}]}
                                        help={"Bei eingehender Buchung wird eine E-Mail an diese Adresse gesendet"}
                                    >
                                        <Input type={"email"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Standard Kategorie"}
                                        name={"defaultCategory"}
                                        help={"Wählen Sie die Kategorie welche standardmäßig ausgewählt ist (Kundenbuchung)"}
                                    >
                                        <AutoComplete
                                            style={{ width: '100%' }}
                                            options={categories}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Farbe"}
                                        name={"color"}
                                        extra={"Diese Farbe wird aktuell nur in den E-Mails verwendet"}
                                    >
                                        <Input type={"color"} />
                                    </Form.Item>
                                </Col>



                                <Col span={24}>
                                    <Form.Item
                                        label={"Logo"}
                                        name={"logo"}
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                    >
                                        <Upload.Dragger
                                            headers={{Authorization: `Bearer ${JWT}`}}
                                            action={`${API_URL}mandator/file`}
                                        ><a>Klicken zum aktualisieren</a></Upload.Dragger>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Buchungskalender Typ"}
                                        name={"bookingCalenderType"}
                                    >
                                        <Select>
                                            <Select.Option value={"ONE"}>Gemeinsam</Select.Option>
                                            <Select.Option value={"PRODUCT"}>Pro Produkt</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Form.Item shouldUpdate noStyle>
                                    {() => form.getFieldsValue(['bookingCalenderType'])?.bookingCalenderType === "ONE" ?
                                        <Col span={12}>
                                            <Form.Item
                                                label={"Buchungskalender Standardansicht"}
                                                name={"bookingCalenderView"}
                                            >
                                                <Select>
                                                    <Select.Option value={"day"}>Täglich</Select.Option>
                                                    <Select.Option value={"week"}>Wöchentlich</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        : null }
                                </Form.Item>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Unbezahlt benachr. E-Mail Adresse"}
                                        name={"unpaidNotificationMail"}
                                        rules={[{type: "email"}]}
                                        help={"E-Mail Adresse an die eine Benachrichtigung bei unbezahlten Rechnungen gesendet wird (immer nachts, nur für Buchungen aus dem Vortag. Wenn keine E-Mail gesetzt ist, wird keine Benachrichtigung gesendet)"}
                                    >
                                        <Input type={"email"} />
                                    </Form.Item>
                                </Col>



                                <Col span={24}>
                                    <Divider orientation="left">Informationen</Divider>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Mandanten ID"}
                                        name={"id"}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Erstellt"}
                                        name={"createdAt"}
                                    >
                                        <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Zuletzt bearbeitet"}
                                        name={"updatedAt"}
                                    >
                                        <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="E-Mail Server" key="2">
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Divider orientation="left">E-Mail Server</Divider>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"E-Mail Host"}
                                        name={"mailHost"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"E-Mail Secure"}
                                        name={"mailSecure"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"E-Mail User"}
                                        name={"mailUser"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"E-Mail Passwort"}
                                        name={"mailPassword"}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"E-Mail Sender Adresse"}
                                        name={"mailSender"}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Form>
            </Spin>
        </Modal>
    );
}

export default MandatorDetail;
