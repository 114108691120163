import React, {useState} from 'react';
import {Row, Col, Card, Button, Input} from "antd";
import moment from "moment";
import CustomerDetail from "./detail";
import {PlusOutlined, DownloadOutlined} from "@ant-design/icons";
import Table from "../../components/Table";
import {downloadFile} from "../../helpers/download-file";

const Customers = () => {


    const [detailId, setDetailId] = useState(null);
    const [toggleReloadState, setToggleReloadState] = useState(null);
    const [search, setSearch] = useState(null);


    const toggleReload = () => {
        setToggleReloadState(Math.random(5));
    };

    const closeDetail = () => {
        setDetailId(null);
        toggleReload();
    };

    const exportCustomers = async () => {
        await downloadFile(`/api/v1/customer/export`, `Kundenliste_${moment().format('YYYYMMDD_HHmm')}.csv`);
    }

    const Titles = {
        "mr": "Herr",
        "mrs": "Frau",
        "company": "Firma",
    };

    const columns = [
        {
            title: 'Anrede',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            render: (data) => Titles?.[data] || ""
        },
        {
            title: 'Vorname',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
        },
        {
            title: 'Nachname',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: true,
        },
        {
            title: 'Adresse',
            dataIndex: 'address',
            key: 'address',
            sorter: true,
        },
        {
            title: 'PLZ',
            dataIndex: 'zipCode',
            key: 'zipCode',
            sorter: true,
        },
        {
            title: 'Ort',
            dataIndex: 'city',
            key: 'city',
            sorter: true,
        },
        {
            title: 'Erstellt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (data) => `${moment(data).format('DD.MM.YYYY HH:mm')} Uhr`
        },

    ];




    return (<>
        <CustomerDetail
            id={detailId}
            close={closeDetail}
        />

        <Row>
                <Col span={24}>
                    <Card>
                        <Row gutter={[10, 10]}>
                            <Col span={8}>
                                <h2>Registrierte Kunden</h2>
                            </Col>
                            <Col span={5}>
                                <div style={{textAlign: 'right'}}>
                                    <Input.Search
                                        allowClear={true}
                                        onSearch={(value) => setSearch(value)}
                                        onBlur={(e) => setSearch(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col span={11}>
                                <div style={{justifyContent: "end", display: "flex", gap: "10px"}}>
                                    <Button
                                        type={"primary"}
                                        icon={<DownloadOutlined />}
                                        onClick={() => exportCustomers()}
                                    >
                                        Exportieren
                                    </Button>
                                    <Button
                                        type={"primary"}
                                        icon={<PlusOutlined />}
                                        onClick={() => setDetailId("new")}
                                    >
                                        Hinzufügen
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
               <Col span={24}>
                   <Card>
                       <Table
                           filter={{search}}
                           endpoint={'customer'}
                           columns={columns}
                           sortField={'createdAt'}
                           sortOrder={'descend'}
                           onClick={(record) => setDetailId(record.id)}
                           toggleReload={toggleReloadState}
                       />
                   </Card>
               </Col>
            </Row>
        </>
    );
}

export default Customers;
