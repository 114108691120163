import React from 'react';
import {Row, Col, Card, Tabs} from "antd";
import PersonalTable from "./personal";
import MandatorTable from "./mandator";
import ProductTable from "./product";
import ResourceTable from "./resource";
import BlocktimeTable from "./blocktime";
import PricelistTable from "./pricelist";

const {TabPane} = Tabs;

const Settings = () => {

    return (
            <Row>
                <Col span={24}>
                    <Card>
                    <h2>Einstellungen</h2>
                    </Card>
                </Col>
               <Col span={24}>
                   <Card>
                       <Tabs defaultActiveKey="1" onChange={() => {}} tabPosition={"left"}>
                           <TabPane tab="Sperrzeiten" key="1">
                               <BlocktimeTable />
                           </TabPane>
                           <TabPane tab="Ressourcen" key="2">
                               <ResourceTable />
                           </TabPane>
                           <TabPane tab="Produkte" key="3">
                               <ProductTable />
                           </TabPane>
                           <TabPane tab="Preislisten" key="4">
                               <PricelistTable />
                           </TabPane>
                           <TabPane tab="Firmendaten" key="5">
                               <MandatorTable />
                           </TabPane>
                           <TabPane tab="Personal" key="6">
                               <PersonalTable />
                           </TabPane>
                       </Tabs>

                   </Card>
               </Col>
            </Row>
    );
}

export default Settings;
