import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row, Space, Spin, TimePicker} from "antd";
import BookingSlot from "./BookingSlot";
import moment from "moment";

const BookingSlotSelector = ({ value, onChange, slots, multiSelect, persons, slotsLoading, onCreate, duration, getDate }) => {

    const [selection, setSelection] = useState(value || []);
    const [form] = Form.useForm();

    useEffect(() => {
        setSelection(value || []);

        // maxbe?
        //onChange && onChange(value);
    }, [value]);


    const Status = {
        0: "Verfügbar",
        1: "Verfügbar",
        2: "Ausgebucht",
    };

    const StatusColor = {
        0: "green",
        1: "orange",
        2: "red",
    };

    const toggleSelection = (slot) => {
        let newSelection = selection;

        // slot already selected?
        if (selection.includes(slot.startDate)) {
            // deselect
            newSelection = selection.filter(sel => sel !== slot.startDate);
        } else {
            if(slot.disabled) {
                return;
            }
            // else select slot...
            // is multiselect?
            if(multiSelect) {
                // then add slot to selection
                newSelection = [...selection, slot.startDate];
            } else {
                // else set slot as only selected one
                newSelection = [slot.startDate];
            }
        }
        setSelection(newSelection);
        onChange && onChange(newSelection);

    };

    const createNew = ({startDate}) => {
        const date = getDate && getDate();
        const dateStr = date.format('YYYY-MM-DD');

        const newItem = {
            startDate: `${dateStr} ${moment(startDate, 'YYYY-MM-DD HH:mm').format("HH:mm")}`,
            endDate: `${dateStr} ${moment(startDate.clone().add(duration), 'YYYY-MM-DD HH:mm').format("HH:mm")}`,
            used: 0,
            available: slots?.[0]?.max || 99,
            max: slots?.[0]?.max || 99,
        };
        onCreate && onCreate(newItem);
        toggleSelection(newItem);
        form.resetFields();
    };


    return (
        <Spin spinning={slotsLoading}>
            <Space size={[8, 16]} direction={"vertical"} style={{width: '100%'}}>
                <Space size={[8, 16]} direction={"vertical"} style={{width: '100%'}}>
                    {!slotsLoading && selection.filter(selectedSlot => !slots.map(slot => slot.startDate).includes(selectedSlot)).map(missingSlot => {
                        return (
                            <BookingSlot
                                key={missingSlot}
                                title={`${moment(missingSlot, 'YYYY-MM-DD HH:mm').format("HH:mm")}`}
                                tag={"nicht verfügbar"}
                                tagColor={"red"}
                                selected={true}
                                onChange={() => toggleSelection({startDate: missingSlot})}
                            />
                        );
                    }) }
                </Space>
                <Space size={[8, 16]} wrap>
                    {slots.map((slot) => {
                        const selected = selection.includes(slot.startDate);

                        let tagText = "";
                        if(slot.status !== undefined) {
                            tagText = Status?.[slot.status] || "";
                        } else {
                            tagText = `${selected ? slot.used + persons : slot.used}/${slot.max}`;
                        }

                        return (
                            <BookingSlot
                                key={slot.startDate}
                                title={`${moment(slot.startDate, 'YYYY-MM-DD HH:mm').format("HH:mm")} - ${slot.endDate ? moment(slot.endDate, 'YYYY-MM-DD HH:mm').format("HH:mm") : moment(slot.startDate, 'YYYY-MM-DD HH:mm').add(duration).format("HH:mm")}`}
                                tag={tagText}
                                tagColor={slot.disabled ? 'red' : (slot.status !== undefined ? StatusColor?.[slot.status] : undefined)}
                                selected={selected}
                                onChange={() => toggleSelection(slot)}
                                disabled={slot.disabled}
                            />
                        );
                    })}
                </Space>
            </Space>
            {onCreate ?
                <Row gutter={15}>
                    <Col span={24}>
                        <br/>
                        <br/>
                        <p>Zeit hinzufügen</p>
                    </Col>
                    <Col span={24}>
                        <Form form={form} onFinish={createNew}>
                            <Row gutter={5}>
                                <Col span={24}>
                                    <Form.Item name='startDate' required={true} rules={[{required: true}]}>
                                        <TimePicker
                                            style={{width: '100%'}}
                                            format={"HH:mm"}
                                            minuteStep={15}
                                            placeholder={"Start Zeit"}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Button block type="primary" htmlType="button" onClick={() => form.submit()}>Hinzufügen</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                : null}
        </Spin>
    )
}

export default BookingSlotSelector;
