import {API_URL} from "../middleware/api";

/**
 * Download file from url with JWT token
 * @param url
 * @param filename
 * @return {Promise<void>}
 */
export const downloadFile = async (url, filename) => {
    // prepare url with api prefix
    const preparedUrl = url.startsWith("/api/v1/") ? `${API_URL}${url.replace("/api/v1/", "")}` : url;

    // get JWT token from local storage
    const jwt = localStorage.getItem("access_token");
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${jwt}`);
    const response = await fetch(preparedUrl, {
        method: 'GET',
        headers: headers,
    });
    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const a = document.createElement
    ('a');
    a.href = urlBlob;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(urlBlob);
    document.body.removeChild(a);
}