import React from 'react';

const PriceTable = ({price, specialPrice}) => {
    return (
        <table style={{width: '100%'}}>
            <thead style={{borderBottom: '1px solid #9e9e9e'}}>
            <th style={{textAlign: 'left'}}>Position</th>
            <th style={{textAlign: 'right'}}>Pers.</th>
            <th style={{textAlign: 'right'}}>Preis</th>
            <th style={{textAlign: 'right'}}>Gesamt</th>
            </thead>
            <tbody>

            {(price?.priceList ||[]).map(actPrice => {

                    if (actPrice?.discount) {
                        return (
                            <tr style={{borderBottom: '1px solid #707d98'}}>
                                <td colSpan={3}>{actPrice.position}</td>
                                <td style={{textAlign: 'right'}}>{actPrice.summary}</td>
                            </tr>
                        );
                    } else {
                        return (
                            <tr>
                                <td>{actPrice.position}</td>
                                <td style={{textAlign: 'right'}}>{actPrice.persons}</td>
                                <td style={{textAlign: 'right', minWidth: '75px', textDecoration: specialPrice ? 'line-through' : 'none'}}>{actPrice.singlePrice}</td>
                                <td style={{textAlign: 'right', minWidth: '75px', textDecoration: specialPrice ? 'line-through' : 'none'}}>{actPrice.summary}</td>
                            </tr>
                        );
                    }

                }

            )}

            <tr style={{borderTop: '1px solid #9e9e9e', paddingTop: '10px'}}>
                <td colSpan={3}><b>Gesamt Brutto</b></td>
                <td style={{textAlign: 'right'}}><b>{(price?.summary || "0").replace(".", ",")} &euro;</b></td>
            </tr>

            </tbody>
        </table>
    );
}

export default PriceTable;
