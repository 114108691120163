import React, {useEffect, useState} from 'react';
import api from "../../../middleware/api";
import {
    Row,
    Col,
    Modal,
    Spin,
    Button,
    DatePicker,
    Divider,
    Input,
    Select,
    InputNumber,
    Space,
    Radio, AutoComplete
} from "antd";
import {Form, message} from "antd";
import {ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import moment from "moment";
import CurrencyInput from "../../../components/CurrencyInput";



const PricelistDetail = ({id, close}) => {

    const [data, setData] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [saving, setSaving] = useState(false);


    const [form] = Form.useForm();

    const fetchData = async () => {
        try {
            setDataLoading(true);
            const response = await api.get(`pricelist/${id}`);
            if(response?.data)
            {
                const data = {...response.data,
                    createdAt: response.data.createdAt ?  moment(response.data.createdAt) : undefined,
                    updatedAt: response.data.updatedAt ? moment(response.data.updatedAt) : undefined,
                };
                setData(data);
            }
        } catch(e) {
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    const deleteData = async () => {
        Modal.confirm({
            title:
                'Möchten Sie diesen Datensatz wirklich löschen?',
            icon: <ExclamationCircleOutlined />,
            content: <span />,
            okText: 'Löschen',
            okType: 'danger',
            cancelText: 'Behalten',
            onOk: async () => {
                try {
                    setDataLoading(true);
                    await api.delete(`pricelist/${id}`);
                    close();
                } catch(e) {
                    message.error("Löschen fehlgeschlagen");
                } finally {
                    setDataLoading(false);
                }
            },
            onCancel() {},
        });
    };


    useEffect(() => {
        setData({});
        if (id && id !== 'new') {
            fetchData();
        }
    }, [id]);

    useEffect(() => {
        form.resetFields();
    }, [data]);


    const handleOk = () => {
        form.submit();
    };

    const handleSubmit = async (values) => {
        try {
            setSaving(true);
            values = {
                ...values,
                id: undefined,
            };

            if (id && id !== 'new') {
                await api.patch(`pricelist/${id}`, values);
            } else {
                await api.put(`pricelist`, values);
            }

            message.success("Gespeichert");
            close();
        } catch(e) {
            message.error("Speichern fehlgeschlagen");
        } finally {
            setSaving(false);
        }
    };


    return (
        <Modal
            maskClosable={false}
            destroyOnClose={true}
            visible={!!id}
            title={id !== 'new' ? "Preisliste bearbeiten" : "Preisliste anlegen"}
            onCancel={close}
            onOk={handleOk}
            width={"80%"}
            footer={[
                <>
                    {id !== 'new' ?
                        <Button key="delete" type="danger" onClick={deleteData}>
                            Löschen
                        </Button>
                    : null}
                </>,
                <Button key="cancel" type="default" onClick={close}>
                    Abbrechen
                </Button>,
                <Button key="ok" type="primary" onClick={handleOk}>
                    Speichern
                </Button>,

            ]}
        >
            <Spin spinning={dataLoading || saving}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={data}
                    layout={"vertical"}
                >

                    <Row
                        gutter={16}
                    >
                        <Col span={24}>
                            <Divider orientation="left">Allgemein</Divider>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label={"Name"}
                                name={"name"}
                                required={true}
                                rules={[{required: true}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={"Art"}
                                name={"type"}
                                required={true}
                                rules={[{required: true}]}
                            >
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value={"discount"}>Gruppenrabatt</Radio>
                                        <Radio value={"tier"}>Preisstaffel</Radio>
                                        <Radio value={"group"}>Gruppenpreis</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={"Mindestpreis (Gesamt)"}
                                name={"minPrice"}
                                help={"0 oder Feld leer lassen um Mindestpreis zu deaktivieren"}
                            >
                                <CurrencyInput />
                            </Form.Item>
                        </Col>

                        {/*<Col span={12}>
                            <Form.Item
                                name={'groupDiscount'}
                                label={"Gruppenrabatt Höhe"}
                            >
                                <InputNumber
                                    min={0}
                                    max={100}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={"Gruppenrabatt ab Anzahl Personen"}
                                name={"groupDiscountMinPersons"}
                            >
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>*/}


                        <Col span={24}>
                            <Divider orientation="left">Preise</Divider>
                        </Col>


                        <Col span={24}>
                            <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.type !== nextValues.type}>

                                {() => form.getFieldsValue(['type'])?.type === 'discount' &&
                                    <Row
                                        gutter={16}
                                    >
                                        <Col span={24}>
                                            <Form.List
                                                name="priceSlot"
                                            >
                                                {(fields, {add, remove}, {errors}) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Form.Item
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <Row gutter={5} align="top">

                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            label={"Preis für"}
                                                                            name={[field.name, 'gameNumber']}
                                                                            required={true}
                                                                            rules={[{required: true}]}
                                                                            help={"Liste wiederholt sich. Wenn jeder Slot den selben Preis hat, nur \"Preis pro Slot\" befüllen. "}
                                                                        >
                                                                            <Select>
                                                                                <Select.Option value={1}>Preis pro
                                                                                    Slot</Select.Option>
                                                                                <Select.Option value={2}>Abw. Preis für jeden 2.
                                                                                    Slot</Select.Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            label={"Preis pro Person"}
                                                                            name={[field.name, 'pricePerPerson']}
                                                                            required={true}
                                                                            rules={[{required: true}]}
                                                                        >
                                                                            <CurrencyInput/>
                                                                        </Form.Item>
                                                                    </Col>


                                                                    <Col span={4}>
                                                                        <Form.Item
                                                                            label={" "}>
                                                                            <MinusCircleOutlined
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => remove(field.name)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Item>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                style={{width: '100%'}}
                                                                icon={<PlusOutlined/>}
                                                            >
                                                                Preis hinzufügen
                                                            </Button>
                                                            <Form.ErrorList errors={errors}/>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>


                                        <Col span={24}>
                                            <Divider orientation="left">Gruppenrabatte</Divider>
                                        </Col>


                                        <Col span={24}>
                                            <Form.List
                                                name="groupDiscount"
                                            >
                                                {(fields, {add, remove}, {errors}) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Form.Item
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <Row gutter={5} align="top">

                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            label={"Ab Personen"}
                                                                            name={[field.name, 'persons']}
                                                                            required={true}
                                                                            rules={[{required: true}]}
                                                                        >
                                                                            <InputNumber style={{width: '100%'}}/>
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            label={"Rabatt"}
                                                                            name={[field.name, 'discount']}
                                                                            required={true}
                                                                            rules={[{required: true}]}
                                                                        >
                                                                            <InputNumber
                                                                                min={0}
                                                                                max={100}
                                                                                formatter={value => `${value}%`}
                                                                                parser={value => value.replace('%', '')}
                                                                                style={{width: '100%'}}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>


                                                                    <Col span={4}>
                                                                        <Form.Item
                                                                            label={" "}>
                                                                            <MinusCircleOutlined
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => remove(field.name)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Item>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                style={{width: '100%'}}
                                                                icon={<PlusOutlined/>}
                                                            >
                                                                Rabatt hinzufügen
                                                            </Button>
                                                            <Form.ErrorList errors={errors}/>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>
                                    </Row>
                                }
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.type !== nextValues.type}>

                                {() => form.getFieldsValue(['type'])?.type === 'tier' &&
                                    <Row
                                        gutter={16}
                                    >
                                        <Col span={24}>
                                            <Form.List
                                                name="priceTier"
                                            >
                                                {(fields, {add, remove}, {errors}) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Form.Item
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <Row gutter={5} align="top">

                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            label={"Personen"}
                                                                            name={[field.name, 'persons']}
                                                                            required={true}
                                                                            rules={[{required: true}]}
                                                                        >
                                                                            <InputNumber
                                                                                min={1}
                                                                                max={100}
                                                                                style={{width: '100%'}}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            label={"Preis pro Person"}
                                                                            name={[field.name, 'pricePerPerson']}
                                                                            required={true}
                                                                            rules={[{required: true}]}
                                                                        >
                                                                            <CurrencyInput/>
                                                                        </Form.Item>
                                                                    </Col>


                                                                    <Col span={4}>
                                                                        <Form.Item
                                                                            label={" "}>
                                                                            <MinusCircleOutlined
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => remove(field.name)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Item>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                style={{width: '100%'}}
                                                                icon={<PlusOutlined/>}
                                                            >
                                                                Preis hinzufügen
                                                            </Button>
                                                            <Form.ErrorList errors={errors}/>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>
                                    </Row>
                                }
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Form.Item shouldUpdate={(prevValues, nextValues) => prevValues.type !== nextValues.type}>

                                {() => form.getFieldsValue(['type'])?.type === 'group' &&
                                    <Row
                                        gutter={16}
                                    >
                                        <Col span={24}>
                                            <Form.List
                                                name="priceGroup"
                                            >
                                                {(fields, {add, remove}, {errors}) => (
                                                    <>
                                                        {fields.map((field, index) => (
                                                            <Form.Item
                                                                required={false}
                                                                key={field.key}
                                                            >
                                                                <Row gutter={5} align="top">

                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            label={"Ab Personen"}
                                                                            name={[field.name, 'persons']}
                                                                            required={true}
                                                                            rules={[{required: true}]}
                                                                        >
                                                                            <InputNumber
                                                                                min={1}
                                                                                max={100}
                                                                                style={{width: '100%'}}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            {...field}
                                                                            label={"Gesamtpreis"}
                                                                            name={[field.name, 'pricePerGroup']}
                                                                            required={true}
                                                                            rules={[{required: true}]}
                                                                        >
                                                                            <CurrencyInput/>
                                                                        </Form.Item>
                                                                    </Col>


                                                                    <Col span={4}>
                                                                        <Form.Item
                                                                            label={" "}>
                                                                            <MinusCircleOutlined
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => remove(field.name)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Item>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                style={{width: '100%'}}
                                                                icon={<PlusOutlined/>}
                                                            >
                                                                Preis hinzufügen
                                                            </Button>
                                                            <Form.ErrorList errors={errors}/>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>
                                    </Row>
                                }
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Divider orientation="left">Preisoptionen</Divider>
                        </Col>

                        <Col span={24}>
                            <Row
                                gutter={16}
                            >
                                <Col span={24}>
                                    <Form.List
                                        name="priceOption"
                                    >
                                        {(fields, {add, remove}, {errors}) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Form.Item
                                                        required={false}
                                                        key={field.key}
                                                    >
                                                        <Row gutter={5} align="top">

                                                            <Col span={10}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label={"Name"}
                                                                    name={[field.name, 'name']}
                                                                    required={true}
                                                                    rules={[{required: true}]}
                                                                >
                                                                    <AutoComplete
                                                                        style={{ width: '100%' }}
                                                                        options={(form.getFieldsValue(['priceOption'])?.priceOption || []).filter(option => option).map(option => ({value: option.name, option: option.name}))}
                                                                    />
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span={5}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label={"Ab Personen"}
                                                                    name={[field.name, 'persons']}
                                                                    required={true}
                                                                    rules={[{required: true}]}
                                                                >
                                                                    <InputNumber
                                                                        min={1}
                                                                        max={100}
                                                                        style={{width: '100%'}}
                                                                    />
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span={5}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label={"Preis pro Person"}
                                                                    name={[field.name, 'pricePerPerson']}
                                                                    required={true}
                                                                    rules={[{required: true}]}
                                                                >
                                                                    <CurrencyInput/>
                                                                </Form.Item>
                                                            </Col>


                                                            <Col span={4}>
                                                                <Form.Item
                                                                    label={" "}>
                                                                    <MinusCircleOutlined
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => remove(field.name)}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                ))}
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        style={{width: '100%'}}
                                                        icon={<PlusOutlined/>}
                                                    >
                                                        Option hinzufügen
                                                    </Button>
                                                    <Form.ErrorList errors={errors}/>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                        </Col>



                        <Col span={24}>
                            <Divider orientation="left">Informationen</Divider>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={"Erstellt"}
                                name={"createdAt"}
                            >
                                <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={"Zuletzt bearbeitet"}
                                name={"updatedAt"}
                            >
                                <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

export default PricelistDetail;
