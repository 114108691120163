import React, {useState} from 'react';
import Table from "../../../components/Table";
import moment from "moment";
import ProductDetail from "./detail";
import {Button} from "antd";
import {PlusOutlined, MenuOutlined} from "@ant-design/icons";
import { SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const ProductTable = () => {

    const [detailVisible, setDetailVisible] = useState(null);
    const [toggleReloadState, setToggleReloadState] = useState(null);

    const toggleReload = () => {
        setToggleReloadState(Math.random(5));
    };

    const closeDetail = () => {
        setDetailVisible(null);
        toggleReload();
    };



    const columns = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Produktname',
            dataIndex: 'name',
            key: 'name',
            sorter: false,
        },
        {
            title: 'Min Personen',
            dataIndex: 'minPersons',
            key: 'minPersons',
            sorter: false,
        },
        {
            title: 'Max Personen',
            dataIndex: 'maxPersons',
            key: 'maxPersons',
            sorter: false,
        },
        {
            title: 'Mindestalter',
            dataIndex: 'minAge',
            key: 'minAge',
            sorter: false,
        },
        {
            title: 'Erstellt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: false,
            render: (data) => `${moment(data).format('DD.MM.YYYY HH:mm')} Uhr`
        },

    ];

    return (<>
            <ProductDetail
                id={detailVisible?.id}
                type={detailVisible?.type}
                close={closeDetail}
            />

            <div style={{marginBottom: '15px', textAlign: 'right'}}>
                <Button.Group>
                <Button
                    type={"primary"}
                    icon={<PlusOutlined />}
                    onClick={() => setDetailVisible({id: "new", type: "PRODUCT"})}
                >
                    Produkt hinzufügen
                </Button>
                <Button
                    type={"default"}
                    icon={<PlusOutlined />}
                    onClick={() => setDetailVisible({id: "new", type: "EVENT"})}
                >
                    Event hinzufügen
                </Button>
                </Button.Group>
            </div>

            <Table
                pagination={false}
                enableDragDrop={true}
                endpoint={'product'}
                columns={columns}
                sortField={'orderIndex'}
                sortOrder={'ascend'}
                onClick={(record) => setDetailVisible({id: record.id, type: record.type })}
                toggleReload={toggleReloadState}
            />
        </>
    );
}

export default ProductTable;
