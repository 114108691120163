import React, {useEffect, useState} from 'react';
import styled  from 'styled-components/macro'
import {Tag} from "antd";


const Wrapper = styled.div`
          border: 1px solid var(--global-calendar-border-colors);
          border-radius: 5px;
          cursor: pointer;
          text-align: center;
          padding: 10px 25px;
          transition: border 0.3s ease-out;
          
          p {
            font-weight: bold;
            margin-bottom: 5px;
          }
          
          &:hover {
            border-color: var(--global-main-color);
          }
          
          &.active {
            /*border-color: var(--global-main-color);
            box-shadow: 0px 0px 7px -1px var(--global-main-color);*/
            background-color: var(--global-main-color);
            color: #000;
          }
          
          &.disabled {
            cursor: not-allowed;
            color: #5c606a;
            border-color: #5c606a;
          }
        `;

const BookingSlot = ({title, tag, tagColor, selected, onChange, disabled, subtitle, fullWidth}) => {


    return (
        <Wrapper onClick={() => onChange(!selected)} className={`${selected ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
            <p>{title}</p>
            {tag ? <Tag color={tagColor || "green"}>{tag}</Tag> : null}
        </Wrapper>
    )
}

export default BookingSlot;
