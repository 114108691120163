import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import Login from './containers/auth/login';
import AuthProvider from "./containers/auth/AuthProvider";
import App from './containers/app';
import moment from "moment";
import 'moment/locale/de';

moment.locale('de');

ReactDOM.render(
  <React.StrictMode>
      <ConfigProvider locale={deDE} >
          <AuthProvider>
              <BrowserRouter>
                  <Routes>
                      <Route path="/" element={<Login />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/app/*"  element={<App />} />
                  </Routes>
              </BrowserRouter>
          </AuthProvider>
      </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
