import React, {useEffect, useState} from 'react';
import api from "../../../middleware/api";
import {
    Row,
    Col,
    Input,
    Modal,
    Spin,
    Button,
    DatePicker,
    Divider,
    Upload,
    Select,
    Checkbox,
    TimePicker,
    InputNumber, Alert
} from "antd";
import {Form, message} from "antd";
import {CloseCircleOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined,} from '@ant-design/icons'
import moment from "moment";



const ResourceDetail = ({id, copyId, close}) => {

    const [data, setData] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [products, setProducts] = useState([]);
    const [pricelist, setPricelist] = useState([]);
    const [error, setError] = useState("")

    const [form] = Form.useForm();

    const weekDays = [
        {
            day: 1,
            name: "Montag"
        },
        {
            day: 2,
            name: "Dienstag"
        },
        {
            day: 3,
            name: "Mittwoch"
        },
        {
            day: 4,
            name: "Donnerstag"
        },
        {
            day: 5,
            name: "Freitag"
        },
        {
            day: 6,
            name: "Samstag"
        },
        {
            day: 7,
            name: "Sonntag"
        }];

    const fetchData = async (_id, copy = false) => {
        try {
            setDataLoading(true);
            const response = await api.get(`resource/${_id}`);
            if(response?.data)
            {
                const data = {...response.data,
                    createdAt: response.data.createdAt ?  moment(response.data.createdAt) : undefined,
                    updatedAt: response.data.updatedAt ? moment(response.data.updatedAt) : undefined,

                    slots1: response.data.slots.filter(slot => slot.dayOfWeek === 1).map(slot => {
                        return {
                            ...slot,
                            startTime: slot.startTime ? moment(slot.startTime, 'HH:mm') : undefined,
                            endTime: slot.endTime ? moment(slot.endTime, 'HH:mm') : undefined,
                        };
                    }),
                    slots2: response.data.slots.filter(slot => slot.dayOfWeek === 2).map(slot => {
                        return {
                            ...slot,
                            startTime: slot.startTime ? moment(slot.startTime, 'HH:mm') : undefined,
                            endTime: slot.endTime ? moment(slot.endTime, 'HH:mm') : undefined,
                        };
                    }),
                    slots3: response.data.slots.filter(slot => slot.dayOfWeek === 3).map(slot => {
                        return {
                            ...slot,
                            startTime: slot.startTime ? moment(slot.startTime, 'HH:mm') : undefined,
                            endTime: slot.endTime ? moment(slot.endTime, 'HH:mm') : undefined,
                        };
                    }),
                    slots4: response.data.slots.filter(slot => slot.dayOfWeek === 4).map(slot => {
                        return {
                            ...slot,
                            startTime: slot.startTime ? moment(slot.startTime, 'HH:mm') : undefined,
                            endTime: slot.endTime ? moment(slot.endTime, 'HH:mm') : undefined,
                        };
                    }),
                    slots5: response.data.slots.filter(slot => slot.dayOfWeek === 5).map(slot => {
                        return {
                            ...slot,
                            startTime: slot.startTime ? moment(slot.startTime, 'HH:mm') : undefined,
                            endTime: slot.endTime ? moment(slot.endTime, 'HH:mm') : undefined,
                        };
                    }),
                    slots6: response.data.slots.filter(slot => slot.dayOfWeek === 6).map(slot => {
                        return {
                            ...slot,
                            startTime: slot.startTime ? moment(slot.startTime, 'HH:mm') : undefined,
                            endTime: slot.endTime ? moment(slot.endTime, 'HH:mm') : undefined,
                        };
                    }),
                    slots7: response.data.slots.filter(slot => slot.dayOfWeek === 7).map(slot => {
                        return {
                            ...slot,
                            startTime: slot.startTime ? moment(slot.startTime, 'HH:mm') : undefined,
                            endTime: slot.endTime ? moment(slot.endTime, 'HH:mm') : undefined,
                        };
                    }),
                };

                if(copy) {
                    delete data.id;
                    delete data.name;
                }

                setData(data);
            }
        } catch(e) {
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    const deleteData = async () => {
        Modal.confirm({
            title:
                'Möchten Sie diesen Datensatz wirklich löschen?',
            icon: <ExclamationCircleOutlined />,
            content: <span />,
            okText: 'Löschen',
            okType: 'danger',
            cancelText: 'Behalten',
            onOk: async () => {
                try {
                    setDataLoading(true);
                    await api.delete(`resource/${id}`);
                    close();
                } catch(e) {
                    message.error("Löschen fehlgeschlagen");
                } finally {
                    setDataLoading(false);
                }
            },
            onCancel() {},
        });
    };

    const loadProducts = async () => {
        const response = await api.get(`product`);
        if(response.data) {
            setProducts(response.data);
        }
    };

    const loadPricelist = async () => {
        const response = await api.get(`pricelist`);
        if(response.data) {
            setPricelist(response.data);
        }
    };



    useEffect(() => {
        setData({});
        if(id) {
            loadProducts();
            loadPricelist();
        }
        if (id && id !== 'new') {
            fetchData(id);
        }
        if(copyId) {
            fetchData(copyId, true);
        }
    }, [id]);

    useEffect(() => {
        form.resetFields();
    }, [data]);


    const handleOk = () => {
        form.submit();
    };

    const handleSubmit = async (values) => {
        try {
            setError("");
            setSaving(true);
            values = {
                ...values,
                id: undefined,
                slots: [
                    ...(values.slots1 ||[]).map(slot => {
                        return {
                            dayOfWeek: 1,
                            startTime: slot.startTime ? slot.startTime.local().format("HH:mm") : undefined,
                            endTime: slot.endTime ? slot.endTime.local().format("HH:mm") : undefined,
                            productList: slot.productList.map(pl => { return { productId: pl.productId, priceListId: pl.priceListId }; })
                        };
                    }),
                    ...(values.slots2 ||[]).map(slot => {
                        return {
                            dayOfWeek: 2,
                            startTime: slot.startTime ? slot.startTime.local().format("HH:mm") : undefined,
                            endTime: slot.endTime ? slot.endTime.local().format("HH:mm") : undefined,
                            productList: slot.productList.map(pl => { return { productId: pl.productId, priceListId: pl.priceListId }; })
                        };
                    }),
                    ...(values.slots3 ||[]).map(slot => {
                        return {
                            dayOfWeek: 3,
                            startTime: slot.startTime ? slot.startTime.local().format("HH:mm") : undefined,
                            endTime: slot.endTime ? slot.endTime.local().format("HH:mm") : undefined,
                            productList: slot.productList.map(pl => { return { productId: pl.productId, priceListId: pl.priceListId }; })
                        };
                    }),
                    ...(values.slots4 ||[]).map(slot => {
                        return {
                            dayOfWeek: 4,
                            startTime: slot.startTime ? slot.startTime.local().format("HH:mm") : undefined,
                            endTime: slot.endTime ? slot.endTime.local().format("HH:mm") : undefined,
                            productList: slot.productList.map(pl => { return { productId: pl.productId, priceListId: pl.priceListId }; })
                        };
                    }),
                    ...(values.slots5 ||[]).map(slot => {
                        return {
                            dayOfWeek: 5,
                            startTime: slot.startTime ? slot.startTime.local().format("HH:mm") : undefined,
                            endTime: slot.endTime ? slot.endTime.local().format("HH:mm") : undefined,
                            productList: slot.productList.map(pl => { return { productId: pl.productId, priceListId: pl.priceListId }; })
                        };
                    }),
                    ...(values.slots6 ||[]).map(slot => {
                        return {
                            dayOfWeek: 6,
                            startTime: slot.startTime ? slot.startTime.local().format("HH:mm") : undefined,
                            endTime: slot.endTime ? slot.endTime.local().format("HH:mm") : undefined,
                            productList: slot.productList.map(pl => { return { productId: pl.productId, priceListId: pl.priceListId }; })
                        };
                    }),
                    ...(values.slots7 ||[]).map(slot => {
                        return {
                            dayOfWeek: 7,
                            startTime: slot.startTime ? slot.startTime.local().format("HH:mm") : undefined,
                            endTime: slot.endTime ? slot.endTime.local().format("HH:mm") : undefined,
                            productList: slot.productList.map(pl => { return { productId: pl.productId, priceListId: pl.priceListId }; })
                        };
                    })
                ],
            };

            delete values.slots1;
            delete values.slots2;
            delete values.slots3;
            delete values.slots4;
            delete values.slots5;
            delete values.slots6;
            delete values.slots7;

            if (id && id !== 'new') {
                await api.patch(`resource/${id}`, values);
            } else {
                await api.put(`resource`, values);
            }

            message.success("Gespeichert");
            close();
        } catch(e) {
            message.error("Speichern fehlgeschlagen");
            if(e.response.data) {
                if(e.response.data?.errorCode === "SLOT_TOO_SMALL_FOR_PRODUCT") {
                    setError(`Mindestens ein Produkt ist länger als der angegebene Slot. (Mindestlänge: ${e.response.data.minDuration})`);
                    window.scrollTo(0,0);
                }
            }
        } finally {
            setSaving(false);
        }
    };


    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList.slice(-1);
    };

    return (
        <Modal
            maskClosable={false}
            destroyOnClose={true}
            visible={!!id}
            title={id !== 'new' ? "Ressource bearbeiten" : "Ressource anlegen"}
            onCancel={close}
            onOk={handleOk}
            width={"80%"}
            footer={[
                <>
                    {id !== 'new' ?
                        <Button key="delete" type="danger" onClick={deleteData}>
                            Löschen
                        </Button>
                    : null}
                </>,
                <Button key="cancel" type="default" onClick={close}>
                    Abbrechen
                </Button>,
                <Button key="ok" type="primary" disabled={saving} loading={saving} onClick={handleOk}>
                    Speichern
                </Button>,

            ]}
        >
            <Spin spinning={dataLoading || saving}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={data}
                    layout={"vertical"}
                >

                    <Row
                        gutter={16}
                    >
                        <Col span={24}>
                            {error && <Alert type={"error"} message={error} showIcon />}
                        </Col>
                        <Col span={24}>
                            <Divider orientation="left">Allgemein</Divider>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={"Name"}
                                name={"name"}
                                required={true}
                                rules={[{required: true}]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Anzahl"}
                                help={"Wieoft ist diese Ressource / Raum verfügbar? (in der Regel nur 1x)"}
                                name={"count"}
                            >
                                <InputNumber min={1} style={{width: '100%'}} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Divider orientation="left">Slots</Divider>
                        </Col>

                        {weekDays.map((actSlot, i) => {

                            return (
                                <>
                                    <Col span={18}>
                                        <Divider orientation="left">{actSlot.name}</Divider>
                                    </Col>
                                    <Col span={6}>
                                        <Button type="default" htmlType="button" onClick={() => weekDays.forEach(day => form.setFieldsValue({ [`slots${day.day}`]: form.getFieldsValue([`slots${actSlot.day}`])?.[`slots${i+1}`] }) )}>Für alle Tage übernehmen</Button>
                                    </Col>
                                    <Col span={24}>
                                        <Form.List
                                            name={`slots${actSlot.day}`}
                                        >
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map(({ key, name, ...restField }) => (
                                                        <Form.Item
                                                            required={false}
                                                            key={key}
                                                        >
                                                            <Row gutter={5}>
                                                                <Col span={6}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'startTime']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Bitte befüllen oder löschen Sie dieses Feld",
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <TimePicker
                                                                            style={{ width: '100%' }}
                                                                            format={"HH:mm"}
                                                                            minuteStep={15}
                                                                            placeholder={"Start Zeit"}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'endTime']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Bitte befüllen oder löschen Sie dieses Feld",
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <TimePicker
                                                                            style={{ width: '100%' }}
                                                                            format={"HH:mm"}
                                                                            minuteStep={15}
                                                                            placeholder={"End Zeit"}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={10}>

                                                                    <Form.List
                                                                        {...restField}
                                                                        name={[name, 'productList']}
                                                                    >
                                                                        {(fields, { add, remove }, { errors }) => (
                                                                            <>
                                                                                {fields.map((field, index) => (
                                                                                    <Form.Item
                                                                                        required={false}
                                                                                        key={field.key}
                                                                                    >
                                                                                        <Row>
                                                                                            <Col span={10}>
                                                                                                <Form.Item
                                                                                                    {...field}
                                                                                                    name={[field.name, 'productId']}
                                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                                    rules={[
                                                                                                        {
                                                                                                            required: true,
                                                                                                            message: "Bitte befüllen oder löschen Sie dieses Feld",
                                                                                                        },
                                                                                                    ]}
                                                                                                    noStyle
                                                                                                >
                                                                                                    <Select
                                                                                                        style={{width: '100%'}}
                                                                                                        showSearch
                                                                                                        optionFilterProp="children"
                                                                                                        placeholder={"Produkt auswählen"}
                                                                                                    >
                                                                                                        {products.map(product => <Select.Option key={product.id} value={product.id}>{product.name}</Select.Option>)}
                                                                                                    </Select>
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                            <Col span={2}></Col>
                                                                                            <Col span={10}>
                                                                                                <Form.Item
                                                                                                    {...field}
                                                                                                    name={[field.name, 'priceListId']}
                                                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                                                    rules={[
                                                                                                        {
                                                                                                            required: true,
                                                                                                            message: "Bitte befüllen oder löschen Sie dieses Feld",
                                                                                                        },
                                                                                                    ]}
                                                                                                    noStyle
                                                                                                >
                                                                                                    <Select
                                                                                                        style={{width: '100%'}}
                                                                                                        showSearch
                                                                                                        optionFilterProp="children"
                                                                                                        placeholder={"Preis auswählen"}
                                                                                                    >
                                                                                                        {pricelist.map(pricelistItem => <Select.Option key={pricelistItem.id} value={pricelistItem.id}>{pricelistItem.name}</Select.Option>)}
                                                                                                    </Select>
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                            <Col span={2}>
                                                                                                <CloseCircleOutlined
                                                                                                    className="dynamic-delete-button"
                                                                                                    onClick={() => remove(field.name)}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Form.Item>
                                                                                    ))}
                                                                                    <Form.Item>
                                                                                        <Button
                                                                                            type="dashed"
                                                                                            onClick={() => add()}
                                                                                            style={{ width: '100%' }}
                                                                                            icon={<PlusOutlined />}
                                                                                        >
                                                                                            Produkt hinzufügen
                                                                                        </Button>
                                                                                        <Form.ErrorList errors={errors} />
                                                                                    </Form.Item>
                                                                                </>
                                                                            )}
                                                                    </Form.List>

                                                                    {/*<Form.Item
                                                                        {...restField}
                                                                        name={[name, 'productIds']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Bitte befüllen oder löschen Sie dieses Feld",
                                                                            },
                                                                        ]}
                                                                        noStyle
                                                                    >
                                                                        <Select
                                                                            mode={"multiple"}
                                                                            style={{width: '100%'}}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                        >
                                                                            {products.map(product => <Select.Option key={product.id} value={product.id}>{product.name}</Select.Option>)}
                                                                        </Select>
                                                                    </Form.Item>*/}
                                                                </Col>
                                                                <Col span={2}>
                                                                    <MinusCircleOutlined
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => remove(name)}
                                                                    />
                                                                </Col>
                                                            </Row>



                                                        </Form.Item>
                                                    ))}
                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{ width: '100%' }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Slot einfügen
                                                        </Button>
                                                        <Form.ErrorList errors={errors} />
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                </>
                            );

                        })}




                        <Col span={24}>
                            <Divider orientation="left">Informationen</Divider>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={"Erstellt"}
                                name={"createdAt"}
                            >
                                <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={"Zuletzt bearbeitet"}
                                name={"updatedAt"}
                            >
                                <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

export default ResourceDetail;
