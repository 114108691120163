import React, {useEffect, useState} from 'react';
import api from "../../middleware/api";
import jwt_decode from "jwt-decode";

let AuthContext = React.createContext(null);

function AuthProvider({ children }) {
    const [JWT, setJWT] = useState(localStorage.getItem('access_token'));
    const [userInfo, setUserInfo] = useState({});
    const [authLoading, setAuthLoading] = useState(false);
    const [mandatorInfo, setMandatorInfo] = useState(JSON.parse(localStorage.getItem("mandatorInfos") || "{}"));

    let signin = async ({username, password}) => {

        try {
            setAuthLoading(true);
            const response = await api.post(`auth/login`, {
                username,
                password,
            });
            if(response.data.access_token) {
                localStorage.setItem("access_token", response.data.access_token);
                localStorage.setItem("refresh_token", response.data.refresh_token);
                setJWT(response.data.access_token);
                return { error: false, JWT: response.data.access_token };
            }
        } catch (e) {
            console.log("login error:", e);
            return { error: true, code: e.response.status, data: e.response.data };
        } finally {
            setAuthLoading(false);
        }


    };

    let signout = async () => {
        setJWT(null);
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
    };

    let changeMandator = async (mandatorId) => {
        try {
            const response = await api.post(`auth/mandator/${mandatorId}`);
            if(response.data.access_token) {
                setJWT(response.data.access_token);
                localStorage.setItem("access_token", response.data.access_token);
                localStorage.setItem("refresh_token", response.data.refresh_token);
                window.location.reload();
                return true;
            }
        } catch (e) {
            console.log("login error:", e);
            return false;
        }
    };

    useEffect(() => {
        if(JWT) {
            const JWTDecoded = jwt_decode(JWT);
            setUserInfo(JWTDecoded);
            loadMandatorInfos(JWTDecoded.mandator);
        } else {
            setUserInfo({});
        }
    }, [JWT]);

    useEffect(() => {
        window.document.title = `Buchungsverwaltung | ${mandatorInfo?.name || ""}`;
    }, [mandatorInfo]);

    const loadMandatorInfos = async (mandator) => {
        try {
            //const response = await api.get(`mandator/${mandatorId}/public`);
            const response = await api.get(`mandator/${mandator}/public`);

            if(response.data) {
                const _mandatorInfos = response.data;
                setMandatorInfo(_mandatorInfos);
                localStorage.setItem("mandatorInfos", JSON.stringify(_mandatorInfos));

            }
        } catch(e) {
            console.error(e);
        }
    };


    let value = { JWT, authLoading, userInfo, changeMandator, signin, signout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export default AuthProvider;
