import React, {useState, useEffect} from 'react';
import {Navigate, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "./AuthProvider";
import {Button, Row, Col, Form, Input, Modal, message, Alert} from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import "./auth.css";

const Login = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [notify, setNotify] = useState({});
    let {signin, signout, authLoading, JWT} = useAuth();

    let from = location.state?.from?.pathname || "/app";
    const [searchParams, setSearchParams] = useSearchParams();

    const [form] = Form.useForm();

    useEffect(() => {
        if(searchParams.get("logout")) {
            setSearchParams({});
            signout();
        }
    }, [searchParams]);

    useEffect(() => {
        if(JWT) {
            navigate(from, { replace: true });
        }
    }, [JWT]);

    const handleSubmit = async (values) => {

        let username = values.username || "";
        let password = values.password || "";

        const loginResponse = await signin({username, password});

        if(!loginResponse.error) {
            message.success("Sie sind nun angemeldet");
        } else {
            switch(loginResponse.code) {
                case 401:
                    setNotify({
                        type: "error",
                        message: "Ihr Benutzername oder Ihr Passwort ist nicht korrekt." });
                    break;
                default:
                    setNotify({
                        type: "error",
                        message: "Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es später erneut." });
                    break;
            }
        }
    }

    return (
        <div className={"login-container"}>
            <Row>
                <Col xs={0} md={12} xl={16}>
                    <div className={"login-image"}>

                    </div>
                </Col>
                <Col xs={24} md={12} xl={8}>
                    <div className={"login-content"}>
                        <h2 className={"login-H1"}>
                            Herzlich Willkommen
                        </h2>
                        <h1 className={"login-H2"}>
                            Anmelden
                        </h1>
                        {notify && notify?.message && <Alert type={notify.type} message={notify.message} banner action={notify?.action} />}
                        <Form
                            form={form}
                            onFinish={handleSubmit}
                        >
                            <Form.Item
                                name={"username"}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie Ihren Benutzernamen ein.'
                                    }
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined style={{ color: 'rgba(255,255,255,.25)' }} />}
                                    placeholder="Benutzername"
                                    size="large"
                                />
                            </Form.Item>


                            <Form.Item
                                name={"password"}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte geben Sie Ihr Passwort ein.'
                                    }
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined style={{ color: 'rgba(255,255,255,.25)' }} />}
                                    type="password"
                                    placeholder="Passwort"
                                    size="large"
                                />
                            </Form.Item>


                            <Form.Item>
                                <Button
                                    className={"login-btn"}
                                    type="primary"
                                    htmlType="submit"
                                    size="large"

                                    loading={authLoading}>
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                        <a  onClick={() => Modal.info({
                            title: 'Sie haben Probleme beim Login?',
                            content: (
                                <div>

                                    <p>Bitte wenden Sie sich an Ihren Systemadministrator.</p>
                                </div>
                            ),
                            onOk() {},
                        })}>
                            Probleme beim Login?
                        </a>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Login;
