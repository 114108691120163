import React, {useState} from "react";
import {Popover, Avatar} from 'antd';
import {LogoutOutlined, SettingOutlined} from '@ant-design/icons';
import {useAuth} from "../auth/AuthProvider";
import {useNavigate} from "react-router-dom";

const SidebarUser = () => {

    const [userMenuOpen, setUserMenuOpen] = useState(0);
    const {userInfo} = useAuth();
    const navigate = useNavigate();


    const getInitials = (name) => {
        let initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        return initials;
    }

    const selectMenu = (route) => {
        setUserMenuOpen(0);
        navigate(route)
    };

    return <Popover placement="top"
                    content={<div className={"sidebar-user-menu"}>

                        { userInfo?.roles?.includes('admin') || userInfo?.roles?.includes('mandatorAdmin') ?
                            <div className={"user-menu-row"} onClick={() => selectMenu("/app/settings")}>
                                <span className={"user-menu-icon"}><SettingOutlined /></span>
                                <span className={"user-menu-text"}>Einstellungen</span>
                            </div>
                            : null }

                        <div className={"user-menu-row"} onClick={() => selectMenu("/login?logout=1")}>
                            <span className={"user-menu-icon"}><LogoutOutlined /></span>
                            <span className={"user-menu-text"}>Abmelden</span>
                        </div>
                    </div>}
                    trigger="click"
                    visible={userMenuOpen}
                    onVisibleChange={(visible) => setUserMenuOpen(visible)}
    >
        <div className={"sidebar-user-wrapper"}>
            <div className={"sidebar-user"}>
                <Avatar className={"sidebar-user-avatar"}
                        size={"large"}>
                    {getInitials(`${userInfo?.firstName} ${userInfo?.lastName}` || "")}
                </Avatar>
                <span className={"sidebar-user-username"}>{`${userInfo?.firstName} ${userInfo?.lastName}`}</span>
            </div>
        </div>
    </Popover>

};

export default SidebarUser;
