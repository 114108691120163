import React, { Suspense } from 'react';

const ReactQuill = React.lazy(() => import('./WYSIWYG'));

const WYSIWYG = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ReactQuill {...props} />
    </Suspense>
  );
};
export default WYSIWYG;
