import React from 'react';
import {
    Modal,
    Button, Tooltip,
} from "antd";
import moment from "moment";
import Table from "../../components/Table";
import {EnvironmentOutlined, EuroCircleOutlined, FrownOutlined, SafetyOutlined} from "@ant-design/icons";

const BookingHistory = ({id, visible, close}) => {


    const Status = {
        "checked": "geprüft",
        "pending": "ausstehend",
        "confirmed": "bestätigt",
        "canceled": "storniert",
    };

    const Titles = {
        "mr": "Herr",
        "mrs": "Frau",
        "company": "Firma",
        "": "",
    };

    const BookingActions = ({record}) => {
        return <div style={{display: 'flex'}}>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Bezahlt"}><EuroCircleOutlined className={`action-button-table ${record.payed ? 'action-button-selected' : ''}`}/></Tooltip></span>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Angekommen"}><EnvironmentOutlined className={`action-button-table ${record.arrived ? 'action-button-selected' : ''}`}/></Tooltip></span>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Eingewiesen"}><SafetyOutlined className={`action-button-table ${record.briefed ? 'action-button-selected' : ''}`}/></Tooltip></span>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Unzufrieden"}><FrownOutlined className={`action-button-table ${record.unhappy ? 'action-button-selected-danger' : ''}`}/></Tooltip></span>
        </div>;
    };


    const columns = [
        {
            title: 'Datum',
            dataIndex: 'date',
            sortField: 'bookingSlots.startDate',
            key: 'date',
            sorter: false,
            render: (data) => `${moment(data).format('DD.MM.YYYY')}`
        },
        {
            title: 'Startzeiten',
            dataIndex: 'bookingSlots',
            key: 'bookingSlots',
            sorter: false,
            render: (data) => `${data.map(slot => moment(slot, 'YYYY-MM-DD HH:mm').format("HH:mm "))}`
        },
        {
            title: 'Produkt',
            dataIndex: 'product',
            sortField: 'product.name',
            key: 'product.name',
            sorter: false,
            render: (data) => `${data?.name}`
        },
        {
            title: 'Ressource / Raum',
            dataIndex: 'resource',
            sortField: 'resource.name',
            key: 'resource.name',
            sorter: false,
            render: (data) => `${data?.name}`
        },
        {
            title: 'Personen',
            dataIndex: 'persons',
            key: 'persons',
            sorter: false,
        },
        {
            title: 'Kunde',
            dataIndex: 'customer',
            key: 'customer',
            sorter: false,
            render: (data, record) => record?.customer ? `${Titles?.[record?.customer?.title]} ${record?.customer?.company || record?.customer?.firstName || ""} ${(record?.customer?.company) ? "" : record?.customer?.lastName || ""}` : 'Gast'
        },
        {
            title: 'Anrede',
            dataIndex: 'guest',
            key: 'guest.title',
            sorter: false,
            render: (data, record) => `${Titles?.[record?.guest?.title] || ""}`
        },
        {
            title: 'Vorname',
            dataIndex: 'customer',
            key: 'guest.firstName',
            sorter: false,
            render: (data, record) => `${record?.guest?.firstName || ""}`
        },
        {
            title: 'Nachname',
            dataIndex: 'customer',
            key: 'guest.lastName',
            sorter: false,
            render: (data, record) => `${record?.guest?.lastName || ""}`
        },
        {
            title: 'Straße, Nr',
            dataIndex: 'address',
            key: 'guest.address',
            sorter: false,
            render: (data, record) => `${record?.guest?.address || ""}`
        },
        {
            title: 'PLZ Ort',
            dataIndex: 'address',
            key: 'guest.zipCode',
            sorter: false,
            render: (data, record) => `${record?.guest?.zipCode || ""} ${record?.guest?.city || ""}`
        },
        {
            title: 'E-Mail',
            dataIndex: 'mail',
            key: 'guest.mail',
            sorter: false,
            render: (data, record) => `${record?.guest?.mail || ""}`
        },
        {
            title: 'Telefon',
            dataIndex: 'phone',
            key: 'guest.phone',
            sorter: false,
            render: (data, record) => `${record?.guest?.phone || ""}`
        },
        {
            title: 'Preis',
            dataIndex: 'price',
            key: 'price',
            sorter: false,
            render: (data) => data ? `${data.replaceAll(".", ",")} €` : ""
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: false,
            render: (data) => Status?.[data]
        },
        {
            title: 'Aktionen',
            dataIndex: 'actions',
            key: 'actions',
            sorter: false,
            render: (data, record) => <BookingActions record={record} />
        },
        {
            title: 'Notiz',
            dataIndex: 'notes',
            key: 'notes',
            sorter: false,
            render: (data, record) => data && data.length > 10 ? `${data.substring(0, 10)}...` : data
        },
        {
            title: 'Bearbeitet',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: false,
            render: (data) => `${moment(data).format('DD.MM.YYYY HH:mm')} Uhr`
        },
        {
            title: 'Bearbeiter',
            dataIndex: 'editorName',
            key: 'editorName',
            sorter: false,
        }

    ];


    return (
        <Modal
            maskClosable={false}
            destroyOnClose={true}
            visible={visible}
            title={"Reservierungsverlauf"}
            onCancel={close}
            width={"80%"}
            footer={[
                <Button key="cancel" type="default" onClick={close}>
                    Schließen
                </Button>,
            ]}
        >
            <Table
                endpoint={`booking/${id}/history`}
                columns={columns}
                sortField={'createdAt'}
                sortOrder={'descend'}
            />
        </Modal>
    );
}

export default BookingHistory;
