import React, {useEffect, useState} from 'react';
import moment from "moment";
import BookingDetail from "./detail";
import Table from "../../components/Table";
import {Button, Card, Checkbox, Col, DatePicker, Input, Row, Select, Tooltip} from "antd";
import {
    PlusOutlined,
    EuroCircleOutlined,
    EnvironmentOutlined,
    SafetyOutlined,
    FrownOutlined,
    CheckCircleOutlined,
    CopyOutlined
} from "@ant-design/icons";
import api from "../../middleware/api";

const BookingTable = () => {

    const defaultFilter = {date: moment().format('YYYY-MM-DD'), hideStorno: true};

    const [detailId, setDetailId] = useState(null);
    const [copyId, setCopyId] = useState(null);
    const [toggleReloadState, setToggleReloadState] = useState(null);

    const [filter, setRawFilter] = useState(defaultFilter);
    const [search, setSearch] = useState(null);
    const [products, setProducts] = useState([]);
    const [categories,setCategories] = useState([]);


    useEffect(() => {
        loadProducts();
        loadCategories();
    }, []);

    useEffect(() => {
        setFilter({search});
    }, [search]);

    const setFilter = (newFilter) => {
        setRawFilter({...filter, ...newFilter});
    };

    const toggleReload = () => {
        setToggleReloadState(Math.random(5));
    };

    const closeDetail = () => {
        setCopyId(null);
        setDetailId(null);
        toggleReload();
    };

    const loadProducts = async () => {
        const response = await api.get(`product`);
        if(response.data) {
            setProducts(response.data);
        }
    };

    const loadCategories = async () => {
        const response = await api.get(`product/categories`);
        if(response.data) {
            setCategories(response.data);
        }
    };


    const runAction = async (record, value, e) => {
        e.preventDefault();
        e.stopPropagation();
        await api.patch(`booking/${record.id}`, {[value] : !record[value] });
        toggleReload();
    };

    const copyBooking = async (record, e) => {
        e.preventDefault();
        e.stopPropagation();
        setCopyId(record.id);
        setDetailId('new');
    }

    const BookingActions = ({record}) => {
        return <div style={{display: 'flex'}}>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Bezahlt"}><EuroCircleOutlined onClick={(e) => runAction(record, "payed", e)} className={`action-button-table ${record.payed ? 'action-button-selected' : ''}`}/></Tooltip></span>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Angekommen"}><EnvironmentOutlined onClick={(e) => runAction(record, "arrived", e)} className={`action-button-table ${record.arrived ? 'action-button-selected' : ''}`}/></Tooltip></span>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Eingewiesen"}><SafetyOutlined onClick={(e) => runAction(record, "briefed", e)} className={`action-button-table ${record.briefed ? 'action-button-selected' : ''}`}/></Tooltip></span>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Unzufrieden"}><FrownOutlined onClick={(e) => runAction(record, "unhappy", e)} className={`action-button-table ${record.unhappy ? 'action-button-selected-danger' : ''}`}/></Tooltip></span>

            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Kopieren"}><CopyOutlined onClick={(e) => copyBooking(record, e)} className={`action-button-table`}/></Tooltip></span>
        </div>;
    };

    const Titles = {
        "mr": "Herr",
        "mrs": "Frau",
        "company": "Firma",
        "": "",
    };

    const Status = {
        "checked": "geprüft",
        "pending": "ausstehend",
        "confirmed": "bestätigt",
        "canceled": "storniert",
        "": "",
    };


    const columns = [
        {
            title: 'B.Nr.',
            dataIndex: 'bookingNr',
            key: 'bookingNr',
            sorter: true,
        },
        {
            title: 'Datum',
            dataIndex: 'bookingSlots.startDate',
            sortField: 'bookingSlots.startDate',
            key: 'date',
            sorter: true,
            render: (data,record) => `${moment(record.date).format('DD.MM.YYYY')}`
        },
        {
            title: 'Startzeiten',
            dataIndex: 'bookingSlots',
            key: 'bookingSlots',
            sorter: false,
            render: (data) => `${data.map(slot => moment(slot, 'YYYY-MM-DD HH:mm').format("HH:mm "))}`
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: false,
            render: (data, record) => `${Titles?.[record?.customer?.title || record?.guest?.title || ""]} ${record?.customer?.company || record?.guest?.company || record?.customer?.firstName || record?.guest?.firstName || ""} ${(record?.customer?.company || record?.guest?.company) ? "" : record?.customer?.lastName || record?.guest?.lastName || ""}`
        },
        {
            title: 'Produkt',
            dataIndex: 'product',
            sortField: 'product.name',
            key: 'product.name',
            sorter: true,
            render: (data) => `${data?.name}`
        },
        {
            title: 'Personen',
            dataIndex: 'persons',
            key: 'persons',
            sorter: true,
        },
        {
            title: 'Preis',
            dataIndex: 'price',
            key: 'price',
            sorter: true,
            render: (data) => data ? `${data.replaceAll(".", ",")} €` : ""
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (data) => Status?.[data]
        },
        {
            title: 'Aktionen',
            dataIndex: 'actions',
            key: 'actions',
            sorter: false,
            render: (data, record) => <BookingActions record={record} />
        },
        {
            title: 'Frage',
            dataIndex: 'messages',
            key: 'messages',
            sorter: true,
            render: (data, record) => data && data.length ? <CheckCircleOutlined style={{color: 'var(--global-main-color)', fontSize: '1.2rem'}} /> : ''
        },
        {
            title: 'Notiz',
            dataIndex: 'notes',
            key: 'notes',
            sorter: true,
            render: (data, record) => data && data.length > 10 ? `${data.substring(0, 10)}...` : data
        },
        {
            title: 'Erstellt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (data) => `${moment(data).format('DD.MM.YYYY HH:mm')} Uhr`
        },
    ];


    return (<>
            <BookingDetail
                id={detailId}
                close={closeDetail}
                initialValues={{date: filter?.date ? moment(filter?.date,'YYYY-MM-DD') : moment()}}
                copyId={copyId}
            />
            <Row>
                <Col span={24}>
                    <Card>
                        <Row gutter={[15, 10]}>
                            <Col xs={24} lg={4}>
                                <h2>Alle Reservierungen</h2>
                            </Col>
                            <Col xs={12} lg={4}>
                                <Checkbox
                                    defaultChecked={defaultFilter.hideStorno}
                                    onChange={(event) => setFilter({hideStorno: event.target.checked})}
                                >Stornierte Buchungen ausbl.</Checkbox>
                            </Col>
                            <Col xs={12} lg={3}>
                                <DatePicker
                                    defaultValue={moment(defaultFilter.date, 'YYYY-MM-DD')}
                                    style={{width: '100%'}}
                                    onChange={(selectedDate) => setFilter({date: selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null})}
                                    placeholder={"Datum"}
                                />
                            </Col>
                            <Col xs={12} lg={3}>
                                <Select
                                    style={{width: '100%'}}
                                    showSearch
                                    optionFilterProp="children"
                                    defaultValue={null}
                                    onChange={(productId, val) => (setFilter({productId}))}
                                >
                                    <Select.Option value={null}>--- Alle Produkte ---</Select.Option>
                                    {products.map(product => <Select.Option key={product.id} value={product.id}>{product.name}</Select.Option>)}
                                </Select>
                            </Col>
                            <Col xs={12} lg={4}>
                                <Select
                                    style={{width: '100%'}}
                                    showSearch
                                    optionFilterProp="children"
                                    defaultValue={null}
                                    onChange={(category, val) => (setFilter({category}))}
                                >
                                    <Select.Option value={null}>--- Alle Kategorien ---</Select.Option>
                                    {categories.map(category => <Select.Option key={category} value={category}>{category}</Select.Option>)}
                                </Select>
                            </Col>
                            <Col xs={12} lg={3}>
                                <div style={{textAlign: 'right'}}>
                                    <Input.Search
                                        allowClear={true}
                                        onSearch={(value) => setSearch(value)}
                                        onBlur={(e) => setSearch(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} lg={3}>
                                <div style={{textAlign: 'right'}}>
                                    <Button
                                        style={{width: '100%'}}
                                        type={"primary"}
                                        icon={<PlusOutlined />}
                                        onClick={() => setDetailId('new')}
                                    >
                                        Hinzufügen
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card>
                        <Table
                            filter={filter}
                            endpoint={'booking'}
                            columns={columns}
                            sortField={'bookingSlots.startDate'}
                            sortOrder={filter?.date ? 'ascend' : 'descend'}
                            onClick={(record) => setDetailId(record.id)}
                            toggleReload={toggleReloadState}
                            rowClassName={(record, index) => `table-clickable ${record.status === 'canceled' ? 'table-row-disabled' : ''}`}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    );

}

export default BookingTable;
