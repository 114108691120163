import React, {useEffect, useState} from 'react';
import api from "../../../middleware/api";
import {
    Row,
    Col,
    Input,
    Modal,
    Spin,
    Button,
    DatePicker,
    Divider,
    Upload,
    Select,
    Checkbox,
    TimePicker,
    Tag
} from "antd";
import {Form, message} from "antd";
import {ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined,} from '@ant-design/icons'
import moment from "moment";



const BlocktimeDetail = ({id, close}) => {

    const [products, setProducts] = useState([]);
    const [data, setData] = useState({});
    const [dataLoading, setDataLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const [form] = Form.useForm();


    const fetchData = async () => {
        try {
            setDataLoading(true);
            const response = await api.get(`blocktime/${id}`);
            if(response?.data)
            {
                const data = {...response.data,
                    createdAt: response.data.createdAt ?  moment(response.data.createdAt) : undefined,
                    updatedAt: response.data.updatedAt ? moment(response.data.updatedAt) : undefined,

                    startDate: response.data.startDate ? moment(response.data.startDate, 'YYYY-MM-DD HH:mm:ss') : undefined,
                    endDate: response.data.endDate ? moment(response.data.endDate, 'YYYY-MM-DD HH:mm:ss') : undefined,

                };
                setData(data);
            }
        } catch(e) {
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    const deleteData = async () => {
        Modal.confirm({
            title:
                'Möchten Sie diesen Datensatz wirklich löschen?',
            icon: <ExclamationCircleOutlined />,
            content: <span />,
            okText: 'Löschen',
            okType: 'danger',
            cancelText: 'Behalten',
            onOk: async () => {
                try {
                    setDataLoading(true);
                    await api.delete(`blocktime/${id}`);
                    close();
                } catch(e) {
                    message.error("Löschen fehlgeschlagen");
                } finally {
                    setDataLoading(false);
                }
            },
            onCancel() {},
        });
    };

    const loadProducts = async () => {
        const response = await api.get(`product`);
        if(response.data) {
            setProducts(response.data);
        }
    };


    useEffect(() => {
        setData({});
        if (id) {
            loadProducts();
        }
        if (id && id !== 'new') {
            fetchData();
        }
    }, [id]);

    useEffect(() => {
        form.resetFields();
    }, [data]);


    const handleOk = () => {
        form.submit();
    };

    const handleSubmit = async (values) => {
        try {
            setSaving(true);
            values = {
                ...values,
                id: undefined,
                startDate: values.startDate ? values.startDate.local().format("YYYY-MM-DD HH:mm") : undefined,
                endDate: values.endDate ? values.endDate.local().format("YYYY-MM-DD HH:mm") : undefined,
            };

            if (id && id !== 'new') {
                await api.patch(`blocktime/${id}`, values);
            } else {
                await api.put(`blocktime`, values);
            }

            message.success("Gespeichert");
            close();
        } catch(e) {
            message.error("Speichern fehlgeschlagen");
        } finally {
            setSaving(false);
        }
    };


    return (
        <Modal
            maskClosable={false}
            destroyOnClose={true}
            visible={!!id}
            title={id !== 'new' ? "Sperrzeit bearbeiten" : "Sperrzeit anlegen"}
            onCancel={close}
            onOk={handleOk}
            width={"80%"}
            footer={[
                <>
                    {id !== 'new' ?
                        <Button key="delete" type="danger" onClick={deleteData}>
                            Löschen
                        </Button>
                    : null}
                </>,
                <Button key="cancel" type="default" onClick={close}>
                    Abbrechen
                </Button>,
                <Button key="ok" type="primary" onClick={handleOk}>
                    Speichern
                </Button>,

            ]}
        >
            <Spin spinning={dataLoading || saving}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={data}
                    layout={"vertical"}
                >

                    <Row
                        gutter={16}
                    >
                        <Col span={24}>
                            <Divider orientation="left">Allgemein</Divider>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label={"Von"}
                                name={"startDate"}
                                required={true}
                                rules={[{required: true}]}
                            >
                                <DatePicker
                                    showTime
                                    style={{width: '100%'}}
                                    format={"DD.MM.YYYY HH:mm"}
                                    minuteStep={15}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label={"Bis"}
                                name={"endDate"}
                                required={true}
                                rules={[{required: true}]}
                            >
                                <DatePicker
                                    showTime
                                    style={{width: '100%'}}
                                    format={"DD.MM.YYYY HH:mm"}
                                    minuteStep={15}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label={"Produkte"}
                                name={"productIds"}
                                extra={"Optional, leer lassen und alle Produkte zu sperren"}
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder={"Alle Produkte"}
                                >
                                    {products.map(product => <Select.Option key={product.id} value={product.id}>{product.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>





                        <Col span={24}>
                            <Divider orientation="left">Informationen</Divider>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={"Erstellt"}
                                name={"createdAt"}
                            >
                                <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={"Zuletzt bearbeitet"}
                                name={"updatedAt"}
                            >
                                <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

export default BlocktimeDetail;
