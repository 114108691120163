import React, {useEffect, useState} from 'react';
import api from "../../../middleware/api";
import {
    Row,
    Col,
    Input,
    Modal,
    Spin,
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Tabs,
    TimePicker,
    InputNumber, Alert, AutoComplete, Select
} from "antd";
import {Form, message} from "antd";
import {ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import moment from "moment";
import WYSIWYG from "../../../components/WYSIWYG";

const {TabPane} = Tabs;

// TYPE = PRODUCT | EVENT

const ProductDetail = ({id, copyId, type, close}) => {

    const [data, setData] = useState({});
    const [categories, setCategories] = useState([]);
    const [combinations, setCombinations] = useState([]);
    const [resources, setResources] = useState([]);
    const [pricelist, setPricelist] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("");


    const [form] = Form.useForm();

    const fetchData = async (_id) => {
        try {
            setDataLoading(true);
            const response = await api.get(`product/${_id}`);
            if(response?.data)
            {
                const data = {...response.data,
                    createdAt: response.data.createdAt ?  moment(response.data.createdAt) : undefined,
                    updatedAt: response.data.updatedAt ? moment(response.data.updatedAt) : undefined,
                    duration: response.data.duration ? moment(response.data.duration, 'HH:mm') : undefined,
                    availableDates: response.data.availableDates && Array.isArray(response.data.availableDates) ? response.data.availableDates.map(actADate => { return {...actADate, startDate: moment(actADate.startDate, "YYYY-MM-DD HH:mm"), endDate: moment(actADate.endDate, "YYYY-MM-DD HH:mm") }; }) : undefined,
                    eventDates: response.data.eventDates && Array.isArray(response.data.eventDates) ? response.data.eventDates.map(actADate => { return {...actADate, startDate: moment(actADate.startDate, "YYYY-MM-DD HH:mm") }; }) : undefined,
                    //postProcessingTime: response.data.postProcessingTime ? moment(response.data.postProcessingTime, 'HH:mm') : undefined,
                };
                setData(data);
                if(data.type !== type) {
                    message.error("Produkttyp stimmt nicht überein");
                    close();
                }
            }
        } catch(e) {
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    const loadCategories = async () => {
        const response = await api.get(`product/categories`);
        if(response.data) {
            setCategories(response.data.map(category => {return {
                label: category,
                value: category
            }}))
        }
    };

    const loadCombinationIds = async () => {
        const response = await api.get(`product/combinations`);
        if(response.data) {
            setCombinations(response.data.map(combination => {return {
                label: combination,
                value: combination
            }}))
        }
    };

    const loadResources = async () => {
        const response = await api.get(`resource`);
        if(response.data) {
            setResources(response.data.map(resource => {return {
                label: resource.name,
                value: resource.id
            }}))
        }
    }

    const loadPricelist = async () => {
        const response = await api.get(`pricelist`);
        if(response.data) {
            setPricelist(response.data.map(pricelist => {return {
                label: pricelist.name,
                value: pricelist.id
            }}))
        }
    }


    const deleteData = async () => {
        Modal.confirm({
            title:
                'Möchten Sie diesen Datensatz wirklich löschen?',
            icon: <ExclamationCircleOutlined />,
            content: <span>Hinweis: Alle Reservierungen mit diesem Produkt werden ebenfalls gelöscht!</span>,
            okText: 'Löschen',
            okType: 'danger',
            cancelText: 'Behalten',
            onOk: async () => {
                try {
                    setDataLoading(true);
                    await api.delete(`product/${id}`);
                    close();
                } catch(e) {
                    message.error("Löschen fehlgeschlagen");
                } finally {
                    setDataLoading(false);
                }
            },
            onCancel() {},
        });
    };


    useEffect(() => {
        setCategories([]);
        setResources([]);
        setCombinations([]);
        setPricelist([]);
        setData({isActive: true});
        if (id) {
            loadCategories();
            loadCombinationIds();
        }
        if (id && id !== 'new') {
            fetchData(id);
        }
        if(copyId) {
            fetchData(copyId);
        }
        if(type === "EVENT") {
            loadResources();
            loadPricelist();
        }
    }, [id, type]);

    useEffect(() => {
        form.resetFields();
    }, [data]);

    const handleOk = () => {
        form.submit();
    };

    const handleSubmit = async (values) => {
        try {
            setError("");
            setSaving(true);
            values = {
                ...values,
                type,
                duration: values.duration ? values.duration.local().format("HH:mm") : undefined,
                availableDates: values.availableDates && Array.isArray(values.availableDates) ? values.availableDates.map(actADate => { return {...actADate, startDate: actADate.startDate.local().format("YYYY-MM-DD HH:mm"), endDate: actADate.endDate.local().format("YYYY-MM-DD HH:mm") }; }) : undefined,
                eventDates: values.eventDates && Array.isArray(values.eventDates) ? values.eventDates.map(actADate => { return {...actADate, startDate: actADate.startDate.local().format("YYYY-MM-DD HH:mm") }; }) : undefined,
                //postProcessingTime: values.postProcessingTime ? values.postProcessingTime.local().format("HH:mm") : undefined,
            };

            if (id && id !== 'new') {
                await api.patch(`product/${id}`, values);
            } else {
                await api.put(`product`, values);
            }

            message.success("Gespeichert");
            close();
        } catch(e) {
            if(e.response.data) {
                if(e.response.data?.errorCode === "PRODUCT_DURATION_TOO_SMALL_FOR_SLOT") {
                    setError(`Mindestens ein Slot (Ressource / Raum) ist kleiner als die angegebene Produktdauer. (Maximallänge: ${e.response.data.maxDuration})`);
                    window.scrollTo(0,0);
                }
            }
            message.error("Speichern fehlgeschlagen");
        } finally {
            setSaving(false);
        }
    };


    return (
        <Modal
            maskClosable={false}
            destroyOnClose={true}
            visible={!!id}
            title={id !== 'new' ? "Produkt bearbeiten" : "Produkt anlegen"}
            onCancel={close}
            onOk={handleOk}
            width={"768px"}
            footer={[
                <>
                    {id !== 'new' ?
                        <Button key="delete" type="danger" onClick={deleteData}>
                            Löschen
                        </Button>
                    : null}
                </>,
                <Button key="cancel" type="default" onClick={close}>
                    Abbrechen
                </Button>,
                <Button key="ok" type="primary" onClick={handleOk}>
                    Speichern
                </Button>,

            ]}
        >
            <Spin spinning={dataLoading || saving}>
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={data}
                    layout={"vertical"}
                >

                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="Allgemein" key="1">

                            <Row
                                gutter={16}
                            >
                                <Col span={24}>
                                    {error && <Alert type={"error"} message={error} showIcon />}
                                </Col>
                                <Col span={24}>
                                    <Divider orientation="left">Allgemein</Divider>
                                </Col>
                                <Col span={20}>
                                    <Form.Item
                                        label={"Produktname"}
                                        name={"name"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={4}>
                                    <Form.Item
                                        label={"Aktiv"}
                                        name={"isActive"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Minimale Anzahl Personen (Slotgröße)"}
                                        name={"minPersons"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"Maximale Anzahl Personen (Slotgröße)"}
                                        name={"maxPersons"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"Produktdauer"}
                                        name={"duration"}
                                        required={true}
                                        rules={[{required: true}]}
                                        validateTrigger={['onChange', 'onBlur']}
                                    >
                                        <TimePicker
                                            style={{ width: '100%' }}
                                            format={"HH:mm"}
                                            minuteStep={15}
                                        />
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"Maximale Anzahl der Reservierungen pro Tag"}
                                        name={"maxBookingPerDay"}
                                        help={"es zählen nur Räume unter 80% Auslastung"}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Kategorie"}
                                        name={"category"}
                                    >
                                        <AutoComplete
                                            style={{ width: '100%' }}
                                            options={categories}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Kombinations ID"}
                                        name={"combinationId"}
                                        extra={"Optional: Produkte mit der selben Kombinations ID können gemeinsam auf einen Slot buchen (Mischbuchung)"}
                                    >
                                        <AutoComplete
                                            style={{ width: '100%' }}
                                            options={combinations}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Zusatz-Tag"}
                                        name={"difficulty"}
                                    >
                                        <AutoComplete options={[{
                                            label: "Anfänger",
                                            value: "Anfänger"
                                        },{
                                            label: "Fortgeschritten",
                                            value: "Fortgeschritten"
                                        },{
                                            label: "Profi",
                                            value: "Profi"
                                        }]} />
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"Mehrere Slots buchbar"}
                                        name={"multiSlotsBooking"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>

                                {/*<Col span={12}>
                                    <Form.Item
                                        label={"Nachbearbeitungszeit"}
                                        name={"postProcessingTime"}
                                        validateTrigger={['onChange', 'onBlur']}
                                    >
                                        <TimePicker
                                            style={{ width: '100%' }}
                                            format={"HH:mm"}
                                            minuteStep={15}
                                        />
                                    </Form.Item>
                                </Col>*/}

                                <Col span={12}>
                                    <Form.Item
                                        label={"Exklusivbuchung"}
                                        name={"exclusiveBooking"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Anzahl verfügbare Plätze anzeigen"}
                                        name={"showFree"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>


                                <Col span={12}>
                                    <Form.Item
                                        label={"Zulässig für Alter von"}
                                        name={"minAge"}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"Zulässig für Alter bis"}
                                        name={"maxAge"}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Standort"}
                                        name={"location"}
                                        help={"Dieser Text ist in der Produktauswahl und in der Kunden E-Mail sichtbar."}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                {/*<Col span={12}>
                                    <Form.Item
                                        label={"Preis pro Person"}
                                        name={"pricePerPerson"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <CurrencyInput />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Gruppenrabatt Höhe"}
                                        name={"groupDiscount"}
                                    >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Gruppenrabatt ab Anzahl Personen"}
                                        name={"groupDiscountMinPersons"}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>*/}

                                {/*<Col span={12}>
                                    <Form.Item
                                        label={"Einverständniserklärung erforderlich"}
                                        name={"declarationConsentRequired"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox />
                                    </Form.Item>
                                </Col>*/}

                                <Col span={12}>
                                    <Form.Item
                                        label={"Link zur Einverständniserklärung"}
                                        name={"declarationConsentLink"}
                                        help={"Wenn dieser Link gesetzt wird, erhält der Kunde zusammen mit seiner Reservierungsbestätigung den Link für die Einverständniserklärung."}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Sperrzeit in Stunden für Kundenbuchungen"}
                                        name={"blockingTime"}
                                        help={"X Stunden vor Terminbeginn sind keine Buchungen oder Stornierungen mehr möglich"}
                                    >
                                        <InputNumber min={0} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Max Tage in Zukunft für Kundenbuchungen"}
                                        name={"maxBookingDaysFuture"}
                                        help={"Buchung maximal X Tage in Zukunft möglich"}
                                    >
                                        <InputNumber min={0} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Divider orientation="left">Nur für spezielle Personenanzahl (optional)</Divider>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Minimale Anzahl Personen (Gruppengröße)"}
                                        name={"minPersonsPerBooking"}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"Maximale Anzahl Personen (Gruppengröße)"}
                                        name={"maxPersonsPerBooking"}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>

                                {type === "PRODUCT" ? (
                                  <>
                                    <Col span={24}>
                                        <Divider orientation="left">Buchbare Zeiten (optional)</Divider>
                                    </Col>
                                    <Col span={24}>
                                        <p>Geben Sie buchbare Zeiträume für dieses Produkt vor. <br/>Wenn keine Zeiten hinterlegt werden, ist das Produkt immer buchbar.</p>
                                        <Form.List
                                            name="availableDates"
                                        >
                                            {(fields, {add, remove}, {errors}) => (
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Form.Item
                                                            required={false}
                                                            key={field.key}
                                                        >
                                                            <Row gutter={5} align="top">

                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        label={"Verfügbar von"}
                                                                        name={[field.name, 'startDate']}
                                                                        required={true}
                                                                        rules={[{required: true}]}
                                                                    >
                                                                        <DatePicker
                                                                            minuteStep={15}
                                                                            showTime
                                                                            style={{width: '100%'}}
                                                                            format={"DD.MM.YYYY HH:mm"}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col span={10}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        label={"Verfügbar bis"}
                                                                        name={[field.name, 'endDate']}
                                                                        required={true}
                                                                        rules={[{required: true}]}
                                                                    >
                                                                        <DatePicker
                                                                            minuteStep={15}
                                                                            showTime
                                                                            style={{width: '100%'}}
                                                                            format={"DD.MM.YYYY HH:mm"}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>


                                                                <Col span={4}>
                                                                    <Form.Item
                                                                        label={" "}>
                                                                        <MinusCircleOutlined
                                                                            className="dynamic-delete-button"
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Form.Item>
                                                    ))}
                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{width: '100%'}}
                                                            icon={<PlusOutlined/>}
                                                        >
                                                            Zeiten hinzufügen
                                                        </Button>
                                                        <Form.ErrorList errors={errors}/>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                  </>) : null}

                                {type === "EVENT" ? (<>
                                    <Col span={24}>
                                        <Divider orientation="left">Event Zeiten</Divider>
                                    </Col>
                                    <Col span={24}>
                                        <p>Geben Sie Zeiträume für dieses Event vor. <br/><span style={{color: "red"}}>In diesen Zeiträumen ist nur dieses Event buchbar in der angegebenen Ressource!</span></p>
                                        <Form.List
                                            name="eventDates"
                                        >
                                            {(fields, {add, remove}, {errors}) => (
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Form.Item
                                                            required={false}
                                                            key={field.key}
                                                        >
                                                            <Row gutter={15} align="top">

                                                                <Col span={7}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        label={"Event start"}
                                                                        name={[field.name, 'startDate']}
                                                                        required={true}
                                                                        rules={[{required: true}]}
                                                                    >
                                                                        <DatePicker
                                                                            minuteStep={15}
                                                                            showTime
                                                                            style={{width: '100%'}}
                                                                            format={"DD.MM.YYYY HH:mm"}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col span={7}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        label={"Ressource / Raum"}
                                                                        name={[field.name, 'resourceId']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        required={true}
                                                                        rules={[{required: true}]}
                                                                    >
                                                                        <Select
                                                                            style={{width: '100%'}}
                                                                        >
                                                                            {resources.map(resource => (
                                                                                <Select.Option key={resource.value} value={resource.value}>{resource.label}</Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col span={7}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        label={"Preisliste"}
                                                                        name={[field.name, 'priceListId']}
                                                                        validateTrigger={['onChange', 'onBlur']}
                                                                        required={true}
                                                                        rules={[{required: true}]}
                                                                    >
                                                                        <Select
                                                                            style={{width: '100%'}}
                                                                        >
                                                                            {pricelist.map(price => (
                                                                                <Select.Option key={price.value} value={price.value}>{price.label}</Select.Option>
                                                                            ))}
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>


                                                                <Col span={3}>
                                                                    <Form.Item
                                                                        label={" "}>
                                                                        <MinusCircleOutlined
                                                                            className="dynamic-delete-button"
                                                                            onClick={() => remove(field.name)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Form.Item>
                                                    ))}
                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{width: '100%'}}
                                                            icon={<PlusOutlined/>}
                                                        >
                                                            Zeiten hinzufügen
                                                        </Button>
                                                        <Form.ErrorList errors={errors}/>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                </>) : null}



                                <Col span={24}>
                                    <Divider orientation="left">Informationen</Divider>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Produkt ID"}
                                        name={"id"}
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Erstellt"}
                                        name={"createdAt"}
                                    >
                                        <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Zuletzt bearbeitet"}
                                        name={"updatedAt"}
                                    >
                                        <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="E-Mail Text" key="2">
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={"Erinnerung"}
                                        name={"reminderMail"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox>Erinnerung an Reservierung per E-Mail senden</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={"E-Mail Text Reservierungsbestätigung"}
                                        name={"mailTextBookingConfirmation"}
                                    >
                                        <WYSIWYG />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Produkt Text" key="3">
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={"Produktdetails"}
                                        name={"productDetails"}
                                    >
                                        <WYSIWYG />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Form>
            </Spin>
        </Modal>
    );
}

export default ProductDetail;
