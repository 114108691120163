import React, {useEffect, useState} from 'react';
import {Input} from "antd";

const CurrencyInput = (props) => {


    const pad = "000";
    const [value, setValue] = useState("");

    useEffect(() => {
        if(props.value) {
            inputChanged(parseFloat(props.value).toFixed(2).toString());
        }
    },[props.value]);

    const getPlaceholder = () => {
        const ds = ","; // decimal seperator
        return (props.prefix || "") + `0${ds}00`;
    }

    const inputChanged = (val) => {

        if(!val) {
            setValue("");
            if (props.onChange) {
                props.onChange(null);
            }
            return;
        }

        const reg = new RegExp(/^\d+$/);
        const ds = ","; // decimal seperator
        const ts = "."; // decimal seperator
        const p = props.prefix || "";

        val = val.replaceAll(ts, "");
        val = val.replaceAll(ds, "");
        val = p ? val.replaceAll(p, "") : val;

        if (reg.test(val)) {
            let str = pad.substring(0, pad.length - val.length) + val

            if (str.length > 3) {
                str = parseInt(str).toString();
                /*for (let i = 0; i < str.length - 3; i++) {
                    if (str[i] === "0") {
                        str = str.substring(1);
                    }
                }*/
            }


            const dec = str.substring(str.length - 2);
            str = str.substring(0, str.length - 2);

            if (props.onChange) {
                const n = Number.parseFloat(`${str}.${dec}`);
                props.onChange(n.toFixed(2));
            }

            let fStr = "" // formatted str

            let t = 0
            for (let i = 0; i < str.length; i++) {
                const last = str[str.length - i - 1];
                if (t > 1) {
                    fStr = ts + last + fStr;
                    t = 0
                } else {
                    fStr = last + fStr
                    t++
                }
            }

            fStr = (props.prefix || "") + (fStr[0] === ts ? fStr.substring(1) : fStr) + ds + dec;
            setValue(fStr);
        }
    }

    return (
        <Input
            value={value}
            placeholder={props.placeholder || getPlaceholder()}
            onChange={(e) => { inputChanged(e.target.value) }}
            addonAfter={"€"}
            allowClear={true}
            onBlur={props.onBlur}
        />
    )
}

export default CurrencyInput;
