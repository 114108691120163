import React, {useState} from 'react';
import Table from "../../../components/Table";
import moment from "moment";
import MandatorDetail from "./detail";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useAuth} from "../../auth/AuthProvider";

const MandatorTable = () => {

    const [detailId, setDetailId] = useState(null);
    const [toggleReloadState, setToggleReloadState] = useState(null);
    const { userInfo } = useAuth();

    const toggleReload = () => {
        setToggleReloadState(Math.random(5));
    };

    const closeDetail = () => {
        setDetailId(null);
        toggleReload();
    };



    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Erstellt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (data) => `${moment(data).format('DD.MM.YYYY HH:mm')} Uhr`
        },

    ];

    return (<>
            <MandatorDetail
                id={detailId}
                close={closeDetail}
            />

            <div style={{marginBottom: '15px', textAlign: 'right'}}>
                { userInfo?.roles?.includes('admin') ? <Button
                    type={"primary"}
                    icon={<PlusOutlined />}
                    onClick={() => setDetailId("new")}
                >
                    Hinzufügen
                </Button> : null }
            </div>

            <Table
                endpoint={'mandator'}
                columns={columns}
                sortField={'createdAt'}
                sortOrder={'descend'}
                onClick={(record) => setDetailId(record.id)}
                toggleReload={toggleReloadState}
            />
        </>
    );
}

export default MandatorTable;
