import React, {useEffect, useState} from 'react';
import {Row, Col, Card, Button, message, Modal, Table, Table as AntTable, Tooltip, Select} from "antd";
import moment from "moment";
import BookingDetail from "./detail";
import {EnvironmentOutlined, EuroCircleOutlined, FrownOutlined, PlusOutlined, SafetyOutlined} from "@ant-design/icons";
import {Calendar, momentLocalizer, Views} from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import api from "../../middleware/api";

const BookingCalendarSingle = ({defaultView}) => {

    const [data, setData] = useState({data: [], fromDate: "", untilDate: ""});
    const [dataLoading, setDataLoading] = useState(false);
    const [detailId, setDetailId] = useState(null);
    const [slotSelected, setSlotSelected] = useState(null);
    const [newDetailInitial, setNewDetailInitial] = useState({});
    const currentDate = React.useRef(null);
    const [filter, setRawFilter] = useState({});
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchData(currentDate.current);
    }, [filter]);

    const setFilter = (newFilter) => {
        setRawFilter({...filter, ...newFilter});
    };

    const loadCategories = async () => {
        const response = await api.get(`product/categories`);
        if(response.data) {
            setCategories(response.data);
        }
    };



    const fetchData = async (from = undefined) => {
        try {
            currentDate.current = from;
            const searchParams = new URLSearchParams({});
            if(from) {
                searchParams.append('from', moment(from).toISOString());
            }

            Object.keys(filter).forEach(filterKey => {
                if (filter[filterKey]) {
                    searchParams.append(filterKey, filter[filterKey]);
                }
            });

            setDataLoading(true);
            const response = await api.get(`booking/calendar?${searchParams.toString()}`);
            if(response?.data?.data)
            {


                let slots = [];

                response.data.data.map((slot) => {
                    let slotIndex = slots.findIndex(
                        (searchSlot) =>
                            searchSlot.startDate.toISOString() === moment(slot.startDate, "YYYY-MM-DD HH:mm").toISOString() && searchSlot.productId === slot.booking.productId
                    );

                    if (slotIndex === -1) {
                        slotIndex =
                            slots.push({
                                id: slot.booking.id,
                                startDate: moment(slot.startDate, "YYYY-MM-DD HH:mm"),
                                start: moment(slot.startDate, "YYYY-MM-DD HH:mm").toDate(),
                                end: moment(slot.endDate, "YYYY-MM-DD HH:mm").toDate(),
                                productId: slot.booking.productId,
                                name: slot.booking.product.name,
                                used: 0,
                                max: slot.booking.product.maxPersons,
                                bookings: [],
                            }) - 1;
                    }

                    slots[slotIndex].used += slot.booking.persons;
                    slots[slotIndex].bookings.push(slot.booking);
                });

                // console.log(slots);

                /*const data = response.data.data.map(actData => { return {
                    ...actData,
                    start: moment(actData.startDate, "YYYY-MM-DD HH:mm").toDate(),
                    end: moment(actData.endDate, "YYYY-MM-DD HH:mm").toDate(),
                }});*/

                setData({...response.data, data: slots});
            }
        } catch(e) {
            console.error(e);
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        loadCategories();
    }, []);

    const handleNavigate = (date) => {
        //setCurrentSelectedDate(date);

        /*const setCurrentSelectedDate = data.data.find(d => {
            const d1 = new Date(d.startDate);
            const d2 = new Date(date);
            return d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate()
        })?.startDate;
         */

        if(!moment(date).isBetween(moment(data.fromDate), moment(data.untilDate))) {
            fetchData(date);
        }
    };

    const closeDetail = () => {
        setDetailId(null);
        setNewDetailInitial({});
        fetchData(currentDate.current);
    };

    const Event = ({ event }) => {
        return (
            <span>
                <strong>{event.name} {event.used} / {event.max}</strong>
            </span>
        )
    }


    const runAction = async (record, value, e) => {
        e.preventDefault();
        e.stopPropagation();
        await api.patch(`booking/${record.id}`, {[value] : !record[value] });
        fetchData(currentDate.current);
    };

    const BookingActions = ({record}) => {
        return <div style={{display: 'flex'}}>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Bezahlt"}><EuroCircleOutlined onClick={(e) => runAction(record, "payed", e)} className={`action-button-table ${record.payed ? 'action-button-selected' : ''}`}/></Tooltip></span>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Angekommen"}><EnvironmentOutlined onClick={(e) => runAction(record, "arrived", e)} className={`action-button-table ${record.arrived ? 'action-button-selected' : ''}`}/></Tooltip></span>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Eingewiesen"}><SafetyOutlined onClick={(e) => runAction(record, "briefed", e)} className={`action-button-table ${record.briefed ? 'action-button-selected' : ''}`}/></Tooltip></span>
            <span style={{flex: 1, margin: '0px 5px'}}><Tooltip title={"Unzufrieden"}><FrownOutlined onClick={(e) => runAction(record, "unhappy", e)} className={`action-button-table ${record.unhappy ? 'action-button-selected-danger' : ''}`}/></Tooltip></span>
        </div>;
    };

    const Titles = {
        "mr": "Herr",
        "mrs": "Frau",
        "company": "Firma",
        "": "",
    };

    const Status = {
        "checked": "geprüft",
        "pending": "ausstehend",
        "confirmed": "bestätigt",
        "canceled": "storniert",
        "": "",
    };


    const columns = [
        {
            title: 'B.Nr.',
            dataIndex: 'bookingNr',
            key: 'bookingNr',
            sorter: true,
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
            render: (data, record) => `${Titles?.[record?.customer?.title || record?.guest?.title || ""]} ${record?.customer?.company || record?.guest?.company || record?.customer?.firstName || record?.guest?.firstName || ""} ${(record?.customer?.company || record?.guest?.company) ? "" : record?.customer?.lastName || record?.guest?.lastName || ""}`
        },
        {
            title: 'Produkt',
            dataIndex: 'product',
            key: 'product.name',
            sorter: true,
            render: (data) => `${data?.name}`
        },
        {
            title: 'Personen',
            dataIndex: 'persons',
            key: 'persons',
            sorter: true,
        },
        {
            title: 'Preis',
            dataIndex: 'price',
            key: 'price',
            sorter: true,
            render: (data) => data ? `${data.replaceAll(".", ",")} €` : ""
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (data) => Status?.[data]
        },
        {
            title: 'Aktionen',
            dataIndex: 'actions',
            key: 'actions',
            sorter: true,
            render: (data, record) => <BookingActions record={record} />
        },
        {
            title: 'Notiz',
            dataIndex: 'notes',
            key: 'notes',
            sorter: true,
            render: (data, record) => data && data.length > 10 ? `${data.substring(0, 10)}...` : data
        },
        {
            title: 'Erstellt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (data) => `${moment(data).format('DD.MM.YYYY HH:mm')} Uhr`
        },

    ];

    const localizer = momentLocalizer(moment);

   /* const firstEventDate = data.data.find(d => {
        const d1 = new Date(d.startDate);
        const d2 = new Date(currentSelectedDate);
        return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
    })?.startDate;

    console.log(firstEventDate);*/

    return (<>
            <BookingDetail
                id={detailId}
                initialValues={newDetailInitial}
                close={closeDetail}
            />

            <Modal
                maskClosable={false}
                destroyOnClose={true}
                visible={!!slotSelected}
                title={"Reservierungen"}
                onCancel={() => setSlotSelected(null)}
                width={"80%"}
                footer={[
                    <Button key="ok" type="primary" onClick={() => setSlotSelected(null)}>
                        Schließen
                    </Button>
                ]}
            >
                <AntTable
                    dataSource={slotSelected && data?.data?.find((d) => d.start.toISOString() === slotSelected.start.toISOString() && d.productId === slotSelected.productId)?.bookings || []}
                    columns={columns}
                    onRow={(record) => ({
                        onClick: () => {
                            setDetailId(record.id);
                        }
                    })}
                    rowClassName={(record, index) => 'table-clickable '}
                />
            </Modal>

            <Row>
                <Col span={24}>
                    <Card>
                        <Row>
                            <Col span={16}>
                                <h2>Alle Reservierungen</h2>
                            </Col>
                            <Col span={4}>
                                <Select
                                    style={{width: '100%'}}
                                    showSearch
                                    optionFilterProp="children"
                                    defaultValue={null}
                                    onChange={(category, val) => (setFilter({category}))}
                                >
                                    <Select.Option value={null}>--- Alle Kategorien ---</Select.Option>
                                    {categories.map(category => <Select.Option key={category} value={category}>{category}</Select.Option>)}
                                </Select>
                            </Col>
                            <Col span={4}>
                                <div style={{textAlign: 'right'}}>
                                    <Button
                                        type={"primary"}
                                        icon={<PlusOutlined />}
                                        onClick={() => setDetailId("new")}
                                    >
                                        Hinzufügen
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card>
                        <Calendar
                            //scrollToTime={data.data.find()}
                            scrollToTime={new Date(1970, 1, 1, 7)}
                            /*selectable
                            onSelectSlot={(newEvent) => {
                                setNewDetailInitial({
                                    startDate: moment(newEvent.start)
                                });
                                setDetailId('new');
                            }}*/
                            defaultView={defaultView === "day" ? Views.DAY : Views.WEEK}
                            messages={{
                                allDay:"ganzer Tag",
                                previous:"Vorheriger",
                                next: "Nächster",
                                today: "Heute",
                                month:"Monat",
                                week: "Woche",
                                day: "Tag",
                                agenda: "Agenda",
                                date: "Datum",
                                time:"Uhrzeit",
                                event:"Ereignis",
                                showMore: (total) => `+ ${total}`
                            }}
                            components={{event: Event}}
                            localizer={localizer}
                            events={data.data}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: "80vh" }}
                            onSelectEvent={(slot) => setSlotSelected({start: slot.start, productId: slot.productId})}
                            onNavigate={(date) => handleNavigate(date)}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default BookingCalendarSingle;
