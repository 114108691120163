import React, {useEffect, useState} from 'react';
import {Row, Col, Card, Button, message, DatePicker, Space, Select} from "antd";
import * as moment from "moment";
import BookingDetail from "./detail";
import {PlusOutlined, TeamOutlined, UserOutlined, GlobalOutlined} from "@ant-design/icons";
import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import api from "../../middleware/api";
import DateHeader from "react-calendar-timeline/lib/lib/headers/DateHeader";
import TimelineHeaders from "react-calendar-timeline/lib/lib/headers/TimelineHeaders";


const BookingCalendarMulti = () => {

    const Titles = {
        "mr": "Herr",
        "mrs": "Frau",
        "company": "Firma",
        "": "",
    };

    const getTimeSpan = (date = null) => {

        const actDate = date ? moment(date) : moment();

        return {
            start: actDate.startOf("day").add(8, 'hour').valueOf(),
            end: actDate.endOf("day").add(2, 'hour').valueOf(),
        };
    };

    const todayTimeSpan = getTimeSpan();


    const [data, setData] = useState({data: [], fromDate: "", untilDate: ""});
    const [dataLoading, setDataLoading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState(null);
    const [newDetailInitial, setNewDetailInitial] = useState({});
    const [detailId, setDetailId] = useState(null);
    const currentDate = React.useRef(null);
    const [filter, setRawFilter] = useState({});

    const [visibleTimeStart, setVisibleTimeStart] = useState(todayTimeSpan.start);
    const [visibleTimeEnd, setVisibleTimeEnd] = useState(todayTimeSpan.end);

    useEffect(() => {
        loadProducts();
        loadCategories();
        fetchData();
    }, []);

    useEffect(() => {
        fetchData(currentDate.current);
    }, [filter]);

    useEffect(() => {
        console.log("categoryFilter", categoryFilter);
        setGroups(categoryFilter ? products.filter(product => product.category === categoryFilter) : products);
    }, [products, categoryFilter]);

    const setFilter = (newFilter) => {
        setRawFilter({...filter, ...newFilter});
    };


    const closeDetail = () => {
        setDetailId(null);
        setNewDetailInitial({});
        fetchData(currentDate.current);
    };


    const loadProducts = async () => {
        const response = await api.get(`product`);
        if(response.data) {
            setProducts(response.data.map(product => {return {
                id: product.id,
                title: product.name,
                category: product.category,
            }}))
        }
    };

    const loadCategories = async () => {
        const response = await api.get(`product/categories`);
        if(response.data) {
            setCategories(response.data);
        }
    };


    const fetchData = async (from = undefined) => {
        try {
            currentDate.current = from;
            const searchParams = new URLSearchParams({});
            if(from) {
                searchParams.append('from', moment(from).toISOString());
            }

            Object.keys(filter).forEach(filterKey => {
                if (filter[filterKey]) {
                    searchParams.append(filterKey, filter[filterKey]);
                }
            });


            setDataLoading(true);
            const response = await api.get(`booking/calendar?${searchParams.toString()}`);

            if(response?.data?.data)
            {
                const data = response.data.data.map(actData => {
                    const actBooking = actData.booking;
                    return {
                            start_time: moment(actData.startDate, "YYYY-MM-DD HH:mm"),
                            end_time: moment(actData.endDate, "YYYY-MM-DD HH:mm"),
                            title: actBooking.name,
                            id: actBooking.id,
                            customerName: `${Titles?.[actBooking?.customer?.title || actBooking?.guest?.title || ""]} ${actBooking?.customer?.company || actBooking?.guest?.company || actBooking?.customer?.firstName || actBooking?.guest?.firstName || ""} ${(actBooking?.customer?.company || actBooking?.guest?.company) ? "" : actBooking?.customer?.lastName || actBooking?.guest?.lastName || ""}`,
                            group: actBooking.productId,
                            canChangeGroup: false,
                            persons: actBooking.persons,
                            online: actBooking.bookingType === 'online'
                        }
                });

                setData({...response.data, data});
            }
        } catch(e) {
            console.error(e);
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };

    const handleNavigate = (date) => {
        if(!moment(date).isBetween(moment(data.fromDate), moment(data.untilDate))) {
            fetchData(date);
        }
    };


    const onPrevClick = () => {
        /*const zoom = visibleTimeEnd - visibleTimeStart;

        setVisibleTimeStart(visibleTimeStart - zoom);
        setVisibleTimeEnd(visibleTimeEnd - zoom);

        handleNavigate(visibleTimeStart - zoom);*/

        const prevDateSpan = getTimeSpan(moment(visibleTimeStart).subtract(1, 'day'));
        setVisibleTimeStart(prevDateSpan.start);
        setVisibleTimeEnd(prevDateSpan.end);

        handleNavigate(prevDateSpan.start);
    };

    const onNextClick = () => {
        /*const zoom = visibleTimeEnd - visibleTimeStart;

        setVisibleTimeStart(visibleTimeStart + zoom);
        setVisibleTimeEnd(visibleTimeEnd + zoom);

        handleNavigate(visibleTimeStart + zoom);*/

        const nextDateSpan = getTimeSpan(moment(visibleTimeStart).add(1, 'day'));
        setVisibleTimeStart(nextDateSpan.start);
        setVisibleTimeEnd(nextDateSpan.end);

        handleNavigate(nextDateSpan.start);
    };

    const onTodayClick = () => {
        /*setVisibleTimeStart(moment().startOf("day").valueOf());
        setVisibleTimeEnd(moment().startOf("day").add(1, "day").valueOf());

        handleNavigate(moment().startOf("day").valueOf());*/

        setVisibleTimeStart(todayTimeSpan.start);
        setVisibleTimeEnd(todayTimeSpan.end);

        handleNavigate(todayTimeSpan.start);
    };

    const jumpToDate = (date) => {
        /*setVisibleTimeStart(moment(date).startOf("day").valueOf());
        setVisibleTimeEnd(moment(date).startOf("day").add(1, "day").valueOf());

        handleNavigate(moment(date).startOf("day").valueOf());*/

        const nextDateSpan = getTimeSpan(moment(date));
        setVisibleTimeStart(nextDateSpan.start);
        setVisibleTimeEnd(nextDateSpan.end);

        handleNavigate(nextDateSpan.start);
    };


    const itemRenderer = ({ item, itemContext, getItemProps }) => {
        const backgroundColor = 'var(--global-second-color)';
        const color = 'var(--global-calendar-header-colors)';
        return (
            <div
                {...getItemProps({
                    style: {
                        backgroundColor,
                        color: color,
                        border: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 3,
                        fontSize: '0.6rem',
                        height: itemContext.dimensions.height,
                    },
                })}
            >
                <div style={{
                    flex: 1,
                    lineHeight: '1rem',
                    display: "flex",
                }}>
                    <div style={{flex: 1}}>
                        <TeamOutlined/>{item.persons}
                    </div>
                    <div style={{flex: 1, textAlign: 'right'}}>
                        {item.online ? <GlobalOutlined style={{width: '10px'}}/> : <UserOutlined/>}
                    </div>
                </div>
                <div style={{
                    flex: 1,
                    lineHeight: '1rem',
                }}>
                    {moment(item.start_time).format("HH:mm")} - {moment(item.end_time).format("HH:mm")}
                </div>
                <div
                    style={{
                        fontWeight: 'bolder',
                        overflow: "hidden",
                        lineHeight: '0.6rem',
                        wordWrap: "break-word",
                        flex: 1
                    }}
                >
                    {itemContext.title}
                </div>
                <div
                    style={{
                        overflow: "hidden",
                        lineHeight: '0.6rem',
                        wordWrap: "break-word",
                        flex: 1
                    }}
                >
                    {item.customerName}
                </div>
            </div>
        );
    };


    return (<>
            <BookingDetail
                id={detailId}
                close={closeDetail}
                initialValues={newDetailInitial}
            />

            <Row>
                <Col span={24}>
                    <Card>
                        <Row>
                            <Col span={20}>
                                <h2>Alle Reservierungen</h2>
                            </Col>
                            <Col span={4}>
                                <div style={{textAlign: 'right'}}>
                                    <Button
                                        type={"primary"}
                                        icon={<PlusOutlined />}
                                        onClick={() => setDetailId("new")}
                                    >
                                        Hinzufügen
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card>

                        <div className="rbc-toolbar">
                            <span className="rbc-btn-group">
                                <button type="button" onClick={onTodayClick}>Heute</button>
                                <button type="button" onClick={onPrevClick}>Vorheriger</button>
                                <button type="button" onClick={onNextClick}>Nächster</button>
                            </span>
                            <span className="rbc-toolbar-label"></span>
                            <span className="rbc-btn-group" style={{width: '50%'}}>
                                <Row gutter={15}>
                                    <Col span={12}>
                                        <Select
                                            style={{width: '100%'}}
                                            showSearch
                                            optionFilterProp="children"
                                            defaultValue={null}
                                            //onChange={(category, val) => (setFilter({category}))}
                                            onChange={(category, val) => (setCategoryFilter(category))}
                                        >
                                            <Select.Option value={null}>--- Alle Kategorien ---</Select.Option>
                                                {categories.map(category => <Select.Option key={category} value={category}>{category}</Select.Option>)}
                                        </Select>
                                    </Col>
                                    <Col span={12}>
                                        <DatePicker
                                            style={{width: '100%'}}
                                            onChange={(selectedDate) => jumpToDate(selectedDate)}
                                            placeholder={"Datum"}
                                        />
                                    </Col>
                                </Row>
                            </span>
                        </div>
                        <Timeline
                            onCanvasClick={(group, time) => {
                                setDetailId('new');
                                setNewDetailInitial({productId: group, date: moment(time)});
                            }}
                            onItemSelect={(id) => setDetailId(id)}
                            selected={[]}
                            groups={groups}
                            items={data.data}
                            canMove={false}
                            canResize={false}
                            lineHeight={75}
                            itemHeightRatio={1.0}
                            itemRenderer={itemRenderer}
                            visibleTimeStart={visibleTimeStart}
                            visibleTimeEnd={visibleTimeEnd}
                        >
                            <TimelineHeaders>
                                <DateHeader unit="day" />
                                <DateHeader unit="hour" labelFormat="HH:mm" />
                            </TimelineHeaders>
                        </Timeline>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default BookingCalendarMulti;
