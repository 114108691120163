import axios from 'axios';
import { message } from 'antd';

//DEV
const API_URL_DEV = "http://localhost:8080/api/v1/";
//export const API_URL = "https://intern.muenchen-lasertag.de/api/v1/";

//PRODUCTION
export const API_URL = process.env.NODE_ENV === "production" ? "/api/v1/" : API_URL_DEV;




const axiosInstance = axios.create({
    baseURL: `${API_URL}`,
    timeout: 5000,
});

// response interceptor for handling common errors (e.g. HTTP 500)
axiosInstance.interceptors.response.use(
    (response) => {return response},
    (error) => errorHandler(error)
);

axiosInstance.interceptors.request.use(
    async config => {
        config.headers = {
            'Authorization': "Bearer " + localStorage.getItem("access_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });


const errorHandler = async (error) => {


    if (!error?.response?.status) {
        message.error(`${error}`);
    }
    else {

        switch (error.response.status) {
            case 401:
                if(!(error.config.url || "").includes('login')) {
                    window.location.href = "/login?logout=1";
                }
                /*if(!(error.config.url || "").includes('login')) {

                    const originalRequest = error.config;
                    if (!originalRequest._retry) {
                        originalRequest._retry = true;
                        const refreshToken = localStorage.getItem('refresh_token');
                        try {
                            const response = await axiosInstance.get(`auth/login/refresh?refreshToken=${refreshToken}`);
                            if(response.data.access_token) {
                                localStorage.setItem("access_token", response.data.access_token);
                                localStorage.setItem("refresh_token", response.data.refresh_token);
                                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                                return axiosInstance(originalRequest);
                            } else {
                                throw new Error("failed to fetch new token");
                            }
                        } catch(e) {
                            window.location.href = "/login?logout=1";
                        }

                    } else {
                        window.location.href = "/login?logout=1";
                    }
                }*/
                break;
            case 403:
                message.error("Sie sind nicht berechtigt diese Aktion auszuführen");
                break;
            default:
                //message.error("Es trat ein Fehler auf");
        }

    }

    return Promise.reject({ ...error })
}


export default axiosInstance;
