import React, {useEffect, useState} from 'react';
import {useAuth} from "../auth/AuthProvider";
import api from "../../middleware/api";
import BookingCalendarSingle from "./singleCalendar";
import BookingCalendarMulti from "./multiCalendar";

const BookingCalendar = () => {

    const [mandatorInfos, setMandatorInfos] = useState({});

    const {userInfo} = useAuth();

    useEffect(() => {
        loadMandatorInfos(userInfo.mandator);
    }, [userInfo?.mandator]);


    const loadMandatorInfos = async (mandatorId) => {
        if(!mandatorId) {
            return;
        }
        const response = await api.get(`mandator/${mandatorId}`);
        if(response.data) {
            setMandatorInfos(response.data);
        }
    };

    return (<>
            {mandatorInfos?.bookingCalenderType === "ONE" ?
                <BookingCalendarSingle defaultView={mandatorInfos.bookingCalenderView} />
                :
                mandatorInfos?.bookingCalenderType === "PRODUCT" ?
                    <BookingCalendarMulti />
                :
                null
            }
        </>
    );
}

export default BookingCalendar;
